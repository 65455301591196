import React from "react";
import Container from "../../components/styles/Container.styled";
import { H1, P } from "../../components/styles/Fonts.styled";
import { Section } from "../../components/styles/Section.styled";
import CurrentVolunteer from "./CurrentVolunteer";
import VolunteerForm from "./VolunteerForm";

const Header = () => {
  return (
    <Section gray>
      <Container>
        <H1 center>Volunteer Registration (UK members only)</H1>
        <P center>
          Please fill in this quick form to register as a volunteer for
          "Pakistani Students in UK" community.{" "}
        </P>
      </Container>
    </Section>
  );
};

export default function index() {
  return (
    <>
      <Header />
      <VolunteerForm />
      <CurrentVolunteer />
    </>
  );
}
