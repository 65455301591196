import React from "react";
import Container from "../styles/Container.styled";
import { Button } from "../styles/Button.styled";
import { H1 } from "../styles/Fonts.styled";
import {
  ButtonWrapper,
  Field,
  FormWrapper,
  Input,
  Label,
  Wrapper,
} from "../styles/Form.styled";

export default function StepTwo(props) {
  const onBTNClickNext = (e) => {
    e.preventDefault();
    props.next();
  };
  const onBTNClickNextPrev = (e) => {
    e.preventDefault();
    props.prev();
  };
  //   console.log(props)

  return (
    <Container>
      <Wrapper>
        <H1 center>{props.title}</H1>
        {/* <H2 center>Initial Infromation</H2> */}
        <FormWrapper>
          <Field>
            <Label>Job Title</Label>
            <Input
              type="text"
              name="job"
              id="job"
              value={props.getState("job")}
              onChange={props.handleChange}
            />
          </Field>
          <Field>
            <Label>Website (Optional)</Label>
            <Input
              type="text"
              name="website"
              id="website"
              value={props.getState("website")}
              onChange={props.handleChange}
            />
          </Field>
          <Field>
            <Label>City</Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={props.getState("city")}
              onChange={props.handleChange}
            />
          </Field>
          <Field>
            <Label>State</Label>
            <Input
              type="text"
              name="state"
              id="state"
              value={props.getState("state")}
              onChange={props.handleChange}
            />
          </Field>
        </FormWrapper>
        <ButtonWrapper>
          <Button onClick={onBTNClickNextPrev}>Prev</Button>
          <Button onClick={onBTNClickNext}>Next</Button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
}
