import moment from "moment";
import React from "react";
import styled from "styled-components";
import { device } from "../../styles/devices";
import { H1, Span } from "../../styles/Fonts.styled";

const Heading = styled(H1)`
  flex: 80% 0 0;
  font-size: 22px;
  padding: 10px 0;
  margin: 0;
  @media ${device.mobile} {
    padding: 5px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 50vh;
  /* margin-bottom: 30px; */
  @media ${device.mobile} {
    height: 30vh;
  }
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
`;
const Details = styled.div`
  display: flex;
  align-items: center;
  flex: 20% 0 0;
  justify-content: flex-end;
`;
const Author = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 0;
  box-shadow: 0px 20px 30px -30px rgb(171 171 171 / 79%);
  background-color: #fff;
`;

const Content = styled.div`
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  /* position: sticky;
    top: 0; */
`;

export default function Header({
  coverImage,
  title,
  authorName,
  authorImage,
  _createdAt,
  categoryName,
}) {
  return (
    <>
      <Image src={coverImage} />
      <InnerWrapper>
        <Content>
          <Head>
            <Heading>
              {title}
              {categoryName && <Span> - {categoryName}</Span>}
            </Heading>
            {authorName && (
              <Details>
                <AuthorImage src={authorImage} />
                <Author>
                  <Span>{authorName}</Span>
                  <Span>{moment(_createdAt).format("MMMM D,YYYY ")}</Span>
                </Author>
              </Details>
            )}
          </Head>
        </Content>
      </InnerWrapper>
    </>
  );
}
