import React from "react";
import { Section } from "../../components/styles/Section.styled";
import { useForm } from "react-hook-form";
import { P } from "../../components/styles/Fonts.styled";

import styled from "styled-components";
import { device } from "../../components/styles/devices";
import { Button } from "../../components/styles/Button.styled";
import client from "../../client";

const FormArea = styled.form`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
  }
`;
const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media ${device.mobile} {
    flex-direction: column;
    padding: 0 20px;
    margin: 0;
  }
`;

const Input = styled.input`
  width: 50%;
  margin: 10px;
  padding: 10px 15px;
  /* border-radius: 5px; */
  /* border-color: 0px 6px 10px #00000029; */
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Label = styled.label`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 4px 0;

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const Select = styled.select`
  width: 50%;
  margin: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  /* border-color: 0px 6px 10px #00000029; */
  color: ${(props) => props.theme.colors.darkText};

  @media ${device.mobile} {
    width: 100%;
  }
`;

const CheckboxField = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

export default function VolunteerForm() {
  const {
    register,
    handleSubmit,
    isSubmitting,
    isSubmitted,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    name: "",
    phoneNumber: "",
    email: "",
    city: "",
    currentlyAsAStudent: "",
    howCanYouHelp: "",
    preference: "",
    situation: "",
    postcode: "",
  });
  const onSubmit = (data) => {
    const volunteer = {
      _id: data.phoneNumber,
      _type: "volunteer",
      createdAt: new Date(),
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      city: data.city,
      currentlyAsAStudent: data.currentlyAsAStudent,
      howCanYouHelp: data.howCanYouHelp,
      preference: data.preference,
      situation: data.situation,
      postcode: data.postcode,
      active: false
    };
    client.createIfNotExists(volunteer);
    alert(
      "Thank you for your interest in volunteering with us. We will get back to you shortly."
    );
    reset();
  };

  // console.log(watch("name"));
  // console.log(errors);
  // watch input value by passing the name of it

  // console.log(isSubmitted);

  return (
    <Section>
      <FormArea onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Input
            placeholder="Your Full Name* "
            type="text"
            {...register("name", { required: "This field is required" })}
          />
          {errors.name && <span>{errors.name.message}</span>}
          <Input
            placeholder="Uk Mobile Number / Whatsapp"
            type="tel"
            {...register("phoneNumber", { required: "This field is required" })}
          />
          {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
        </Field>
        <Field>
          <Input placeholder="Your Email" type="email" {...register("email")} />
          <Input
            placeholder="City / University You are currently in"
            type="text"
            {...register("city", { required: "This field is required" })}
          />
          {errors.phoneNumber && <span>{errors.phoneNumber.message}</span>}
        </Field>
        <Field>
          <Input
            placeholder="Your postcode (first 3 letters are fine - eg: W1T)"
            type="text"
            {...register("postcode", { required: "This field is required" })}
          />
          <Select
            {...register("currentlyAsAStudent", {
              required: "This field is required",
            })}
          >
            <option value="" disabled selected hidden>
              Are you currently a student?
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
            <option value="fulltime">
              Graduated and now working full time
            </option>
            <option value="business">
              Graduated and own business(es) in UK
            </option>
          </Select>
          {errors.currentlyAsAStudent && (
            <span>{errors.currentlyAsAStudent.message}</span>
          )}
        </Field>
        <CheckboxField>
          <P>How can you help?</P>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[0]"
              {...register("howCanYouHelp", {
                required: "This field is required",
              })}
              value="Talking with new/struggling students on phone and chat"
            />
            Talking with new/struggling students on phone and chat
          </Label>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[1]"
              {...register("howCanYouHelp")}
              value="happy to meet students nearby"
            />
            happy to meet students nearby
          </Label>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[2]"
              {...register("howCanYouHelp")}
              value="i can connect them with employers/help with jobs"
            />
            i can connect them with employers/help with jobs
          </Label>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[3]"
              {...register("howCanYouHelp")}
              value="Help with accommodation"
            />
            Help with accommodation
          </Label>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[4]"
              {...register("howCanYouHelp")}
              value="I would like to sponsor someone's food ration pack for a week"
            />
            I would like to sponsor someone's food ration pack for a week
          </Label>
          <Label>
            <input
              type="checkBox"
              name="howCanYouHelp[5]"
              {...register("howCanYouHelp")}
              value="I would like to sponsor someone's food ration pack for the entire month of Ramadan"
            />
            I would like to sponsor someone's food ration pack for the entire
            month of Ramadan
          </Label>
        </CheckboxField>
        <br />

        <Field>
          <Select
            {...register("situation", { required: "This field is required" })}
          >
            <option value="" disabled selected hidden>
              What best describe your situation?
            </option>
            <option
              value="Single student living on my own and looking to connect with
            Pakistani students."
            >
              Single student living on my own and looking to connect with
              Pakistani students.
            </option>
            <option
              value="I am here with dependent and we're looking to connect with
            likeminded couples/families"
            >
              I am here with dependent and we're looking to connect with
              likeminded couples/families
            </option>
            <option value="I live with a group of other students.">
              I live with a group of other students.
            </option>
          </Select>
          {errors.situation && <span>{errors.situation.message}</span>}
          <Select
            {...register("preference", { required: "This field is required" })}
          >
            <option value="" disabled selected hidden>
              Any other preference?
            </option>
            <option value="Add me to female only group.">
              Add me to female only group.
            </option>
            <option value="I am happy to connect with those of opposite gender.">
              I am happy to connect with those of opposite gender.
            </option>
          </Select>
          {errors.preference && <span>{errors.preference.message}</span>}
        </Field>
        <Button disabled={isSubmitting}>Submit</Button>
        {isSubmitted && <p>Thank you for your submission</p>}
      </FormArea>
    </Section>
  );
}
