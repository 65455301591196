import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import GlobalStyles from "./components/styles/Global";
import Routing from "./routing";

const theme = {
  colors: {
    primary: "#4F46E5",
    // primary: "#012269",
    secondary: "#F9F9F9",
    darkText: "#707070",
    lightText: "#E5E5E5",
    black: "#121827",
    lightGrey: "#9DA4B0",
  },
  textSize: {
    xl: "42px",
    lg: "32px",
    md: "22px",
    sm: "16px",
    xs: "14px",
  },
};

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Navbar />
          <Routing />
          <Footer />
        </>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
