import React from "react";
import styled from "styled-components";
import { H2, Span } from "../../styles/Fonts.styled";
import { getYear } from "../../utils/date";
import { BsTrash } from "react-icons/bs";

const InnerWrapper = styled.div``;
const ExperienceSection = styled.div``;
const Head = styled.div``;
const IconContainer = styled.div``;
const ActionIcon = styled.span``;

export default function Experience(props) {
  const handleDelete = (index) => {
    const newArr = props.getState("experiences", []);
    newArr.splice(index, 1);
    props.setState("experiences", newArr);
  };

  const { title, startDate, endDate } = props.experience;
  return (
    <ExperienceSection>
      <InnerWrapper>
        <Head>
          <H2> {title} </H2>
          <Span>
            {getYear(startDate)} -{" "}
            {Date.parse(endDate) ? getYear(endDate) : endDate}
          </Span>
        </Head>
        <IconContainer>
          <ActionIcon onClick={() => handleDelete(props.id)}>
            <BsTrash />
          </ActionIcon>
        </IconContainer>
      </InnerWrapper>
    </ExperienceSection>
  );
}
