import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../../components/styles/Container.styled";
import { H2 } from "../../../components/styles/Fonts.styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Card = styled.div`
  flex: 50% 0 0;
  margin: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled(Link)`
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.primary};
  margin-top: 10px;
  font-size: 14px;
`;

const Heading = styled(H2)`
  color: white;
`;

// const Para = styled(P)`
//   color: white;
// `;

export default class ExploreMore extends Component {
  render() {
    return (
      <Container>
        <Wrapper>
          <Card>
            <Heading>View other members</Heading>
            {/* <Para>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Para> */}
            <Button to="/users">View all</Button>
          </Card>
          <Card>
            <Heading>Apply to be a mentor</Heading>
            {/* <Para>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Para> */}
            <Button to="/users">Apply now</Button>
          </Card>
        </Wrapper>
      </Container>
    );
  }
}
