import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { H2, Span } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const Card = styled.div`
  width: 33%;
  margin: 20px 10px;
  padding: 20px;
  box-shadow: 0px 6px 10px #00000029;
  border-radius: 15px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 200px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Name = styled(H2)`
  color: white;
  text-shadow: 1px 1px 1px #000;
`;

const Title = styled(Span)`
  color: white;
  text-shadow: 1px 1px 1px #000;
`;

export default function University({ universities, title }) {
  // console.log(universities);
  return (
    <Section>
      <Container>
        <Wrapper>
          {universities.map((university, index) => {
            // console.log("slugggg ======>>", university.slug);
            return (
              <Card bg={university.image} key={index}>
                <Link to={university.slug.current}>
                  <a href={university.Link}>
                    <Name>{university.name}</Name>
                    <Title>{title}</Title>
                  </a>
                </Link>
              </Card>
            );
          })}
        </Wrapper>
      </Container>
    </Section>
  );
}
