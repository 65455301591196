import React from "react";
import { Route, Routes } from "react-router-dom";
// import University from "../components/discover/University";
import DiscoverId from "../pages/Discover/DiscoverId";
import GuideBlog from "../pages/Guide/GuideBlog";
import {
  Home,
  Discover,
  Templates,
  Guide,
  Resume,
  Consultation,
  Calculator,
  Faqs,
  Volunteer,
  NeedHelp,
  NotFound,
  Login,
  Signup,
  Settings,
  Profile,
  Users,
  Universities,
  UniversityPage,
  TermsAndConditions,
  PrivacyPolicy,
  FinancialStatementCalculator,
  IhsCalculator,
} from "../pages/index";
import TemplateDetails from "../pages/Templates/TemplateDetails";
import ProtectedRoutes from "./ProtectedRoutes";

export default function Routing() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/discover/:slug" element={<DiscoverId />} />

        <Route path="/discover/:slug/:slug" element={<UniversityPage />} />

        <Route path="/consultation" element={<Consultation />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/guide/:slug" element={<GuideBlog />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/templates/:slug" element={<TemplateDetails />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/need-help" element={<NeedHelp />} />
        <Route path="/universities" element={<Universities />} />
        <Route path="/universities/:slug" element={<UniversityPage />} />

        <Route path="/calculator" element={<Calculator />} />
        <Route
          path="/calculator/financial-statement-calculator"
          element={<FinancialStatementCalculator />}
        />
        <Route path="/calculator/ihs-calculator" element={<IhsCalculator />} />
        <Route path="/faqs" element={<Faqs />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/universities" element={<Universities />} />
          <Route path="/universities/:slug" element={<UniversityPage />} /> */}
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<Profile />} />
      </Routes>
    </>
  );
}
