import React from "react";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { device } from "../styles/devices";
import { H1, Span } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";

// const Section = styled.div`
//     width: 100%;
// `
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;

  @media ${device.mobile} {
    flex-direction: column;
    margin: 0;
  }
`;
const LeftSection = styled.div`
  flex: 1;
`;
const Head = styled.div``;
const Content = styled.div`
  padding-right: 25px;
  @media ${device.mobile} {
    padding-right: 0;
  }
`;
const Text = styled.p`
  font-size: ${({ theme }) => theme.textSize.sm};
  color: ${({ theme }) => theme.colors.darkText};
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
const RightSection = styled.div`
  width: 450px;

  @media ${device.mobile} {
    width: 100%;
    margin: 20px;
  }
`;
const Image = styled.img`
  width: 100%;
`;

export default function About() {
  return (
    <Section>
      <Container>
        <Wrapper>
          <LeftSection>
            <Head>
              <Span>WHOAMI</Span>
              <H1>
                Hey, I’m <span>Fahad Saleem!</span>
              </H1>
            </Head>
            <Content>
              <Text>
                Some of you may know me from my Facebook group{" "}
                <span>“Pakistani Students in the UK”.</span>{" "}
              </Text>
              <br />
              <Text>
                I was once in your shoes and went through similar stress of
                applying for studies abroad. Although that was 14 years ago and
                I now live permanently in the UK, unfortunately the study abroad
                consultancy hasn’t changed much in Pakistan.{" "}
              </Text>
              <br />
              <Text>
                I have been working as an Education and career consultant based
                in UK since almost a decade. My students come from various
                different parts of the world and many are now settled in the UK.
              </Text>
              <br />
              <Text>
                <span>
                  “With me and my team by your side you’ll have not only get an
                  offer but also one-on-one mentoring along with advice
                  throughout every step as we go through these confusing
                  processes together.”
                </span>
              </Text>
              <br />
              <Text>
                I am on a mission to equip my fellow Pakistani students with
                everything they need to be succesfull in their study abroad(and
                beyond) journey.
              </Text>
            </Content>
          </LeftSection>
          <RightSection>
            <Image src="/images/whoami@2x.png" />
          </RightSection>
        </Wrapper>
      </Container>
    </Section>
  );
}
