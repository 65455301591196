import React from "react";
import styled from "styled-components";

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ContactText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  letter-spacing: -0.03em;
  margin-bottom: 4px;
  font-weight: 500;
`;

const Contact = (props) => {
  return (
    <ContactItem>
      <ContactText>{props.text}</ContactText>
    </ContactItem>
  );
};

export default Contact;
