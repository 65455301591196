import styled from "styled-components";
import { device } from "./devices";

// const Container = styled.div`
//   width: 1320px;
//   max-width: 100%;
//   margin: 0 auto;
//   @media ${device.mobile} {
//     padding: 0 20px;
//     width: 100%;
//   }
// `;

const Container = styled.div`
  /* max-width: 1440px; */
  margin: 0 auto;
  /* width: 100%; */
  

  @media ${device.mobile} {
    max-width: 100%;
    padding: 0 1.35rem;
  }
  @media ${device.tablet} {
    max-width: 100%;
    padding: 0 1.45rem;
  }
  @media ${device.laptop} {
    max-width: 100%;
    padding: 0 2.75rem;
  }
  @media ${device.desktop} {
    max-width: 1440px;
    padding: 0 7rem;
  }
`;

export default Container;
