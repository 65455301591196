import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styled from "styled-components";
import { H2, Span } from "../../../components/styles/Fonts.styled";
import Switch from "react-switch";
import {
  Field,
  FormWrapper,
  Input,
  Label,
} from "../../../components/styles/Form.styled";
import { Button } from "../../../components/styles/Button.styled";
import sanityClient from "../../../client";
// import Loading from "../../../components/loading/Loading";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Select = styled.select`
  padding: 5px 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid rgb(213, 213, 213);
  border-radius: 5px;
`;

export default function SideBarModal(props) {
  const [formState, setFormState] = useState({
    name: props.user.name || "",
    phoneNumber: props.user.phoneNumber || "",
    city: props.user.city || "",
    cityId: props.user.cityId || "",
    university: props.user.university || "",
    universityId: props.user.universityId || "",
    postcode: props.user.postcode || "",
    degree: props.user.degree || "",
    degreeType: props.user.degreeType || "",
    currentlyAsAStudent: props.user.currentlyAsAStudent || false,
  });

  const [allUniversities, setAllUniversities] = useState([]);
  const [cities, setCities] = useState([]);
  const [checked, setChecked] = useState(props.user.currentlyAsAStudent);

  const navigate = useNavigate();

  const switchHandleChange = (val) => {
    setChecked(val);
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  // console.log("form State after state update", formState);

  const fetchUnistities = async () => {
    await sanityClient
      .fetch(
        `*[_type == "university"]{
        name,
        "id": _id
    }`
      )
      .then((res) => {
        setAllUniversities(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCities = async () => {
    await sanityClient
      .fetch(
        `*[_type == "discover"]{
      title,
      "id": _id
  }`
      )
      .then((res) => {
        setCities(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUnistities();
    fetchCities();
  }, []);

  const postData = (data) => {
    const mutate = {
      mutations: [
        {
          patch: {
            id: props.user.id,
            set: {
              name: data.name,
              phoneNumber: data.phoneNumber,
              city: {
                _ref: data.cityId,
                _type: "reference",
              },
              postcode: data.postcode,
              university: {
                _ref: data.universityId,
                _type: "reference",
              },
              degree: data.degree,
              degreeType: data.degreeType,
              currentlyAsAStudent: checked,
            },
          },
        },
      ],
    };

    // console.log("im the data from mutation: ", mutate);

    const myPromise = fetch(
      `https://${process.env.REACT_APP_SANITY_PROJECT_ID}.api.sanity.io/${process.env.REACT_APP_SANITY_VERSION}/data/mutate/${process.env.REACT_APP_SANITY_DATASET_NAME}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_PROJECT_TOKEN}`,
        },
        body: JSON.stringify(mutate),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          props.onClose();
          navigate(`/profile/${props.user.id}`);
          // reload page
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));

    toast.promise(myPromise, {
      loading: "Sending data to server..",
      success: "Profile updated successfully",
      error: "Error when fetching",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState({ ...formState, [e.target.name]: e.target.value });
    // console.log("form data", { [e.target.name]: e.target.value });
    // console.log(formState);

    postData(formState);
  };

  // console.log("cities", cities);
  // console.log("allUniversities", allUniversities);
  // console.log("state user", props.user);

  return (
    <>
      <Toaster />
      <Modal
        open={props.open}
        onClose={props.onClose}
        closeOnOverlayClick={true}
      >
        <div>
          <H2>Basic Details</H2>
          <Span>All fields are required</Span>
          <form onSubmit={handleSubmit}>
            <FormWrapper>
              <Field>
                <Label>Full Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formState.name}
                  onChange={handleChange}
                  required
                />
              </Field>
              <Field>
                <Label>Phone Number</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="+44xxxxxxxxx"
                  value={formState.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </Field>
              <Field>
                <Label>Current City</Label>
                <Select onChange={handleChange} name="cityId" required>
                  <option value={props.user.cityId || "Default"} selected>
                    {props.user.city || "Select City"}
                  </option>
                  {cities &&
                    cities.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.title}
                      </option>
                    ))}
                </Select>
              </Field>
              <Field>
                <Label>Postcode</Label>
                <Input
                  type="text"
                  name="postcode"
                  id="postcode"
                  value={formState.postcode}
                  onChange={handleChange}
                />
              </Field>
              <Field>
                <Label>University</Label>

                <Select onChange={handleChange} name="universityId">
                  <option value={props.user.universityId || "Default"}>
                    {props.user.university || "Select University"}
                  </option>
                  {allUniversities &&
                    allUniversities.map((university) => (
                      <option value={university.id} key={university.id}>
                        {university.name}
                      </option>
                    ))}
                </Select>
              </Field>

              <Field>
                <Label>Degree Type</Label>
                <Select onChange={handleChange} name="degreeType">
                  <option value={props.user.degreeType || "default"}>
                    {props.user.degreeType || "Select Degree Type"}
                  </option>
                  <option value="Foundation">Foundation</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="postgraduate">postgraduate</option>
                  <option value="PHD">PHD</option>
                </Select>
              </Field>
              <Field>
                <Label>Course Name</Label>
                <Input
                  type="text"
                  name="degree"
                  id="degree"
                  placeholder="Project Management"
                  value={formState.degree}
                  onChange={handleChange}
                />
              </Field>
              <Field>
                <Label>Are you currently as a student?</Label>
                <Switch
                  onChange={switchHandleChange}
                  checked={checked}
                  name="currentlyAsAStudent"
                />
              </Field>
              <Span>Server takes few mins to update information</Span>
            </FormWrapper>

            <Button>Submit</Button>
          </form>
        </div>
      </Modal>
    </>
  );
}
