import React from "react";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { device } from "../styles/devices";
import { H1, P, Span } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";

// const SectionWrapper = styled(Section)`
//     padding: 70px 0;
//     background-color: ${({ theme }) => theme.colors.secondary};

//     @media ${device.mobile}{
//         padding: 40px 0
//     }
// `

const Head = styled.div`
  padding: 0 20px 10px;
  p {
    font-weight: 200;
    font-size: ${({ theme }) => theme.textSize.xs};
    color: ${({ theme }) => theme.colors.darkText};
  }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Step = styled.div`
  width: 30%;
  padding: 0 20px;
  span {
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.6;
    border-radius: 50%;
    border: none;
    padding: 15px 18px;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 30px;
  }
  p {
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.darkText};
    font-weight: 300;
    padding: 30px 0 20px;
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      color: ${({ theme }) => theme.colors.darkText};
      line-height: 1.5;
      font-size: ${({ theme }) => theme.textSize.sm};
      font-weight: 300;
    }
  }

  @media ${device.mobile} {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
`;

export default function Process() {
  return (
    <Section gray>
      <Container>
        <Head>
          <Span>HOW IT WORKS</Span>
          <H1>
            GET STARTED WITH <span>THREE EASY STEPS.</span>
          </H1>
        </Head>
        <Steps>
          <Step>
            <span>01</span>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </P>
            {/* <ul>
                            <li>- talk about your plans</li>
                            <li>- analyse your profile</li>
                            <li>- find the right fit of universities</li>
                            <li>- clear all your doubts</li>
                            <li>- clear all your doubts</li>
                            <li>and</li>
                            <li>- Answer any burning questions you may have</li>
                        </ul> */}
          </Step>
          <Step>
            <span>02</span>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
            </P>
            {/* <ul>
                            <li>- Review your CV & Personal Statement so they
                                highlight your interests, life experiences/goals,
                                education gaps, etc.</li>
                            <li>- Shortlist 5-7 Universities based on your
                                preferences, budget and profile.</li>
                            <li>- Submit and track your applications ensuring
                                speedy responses..</li>
                        </ul> */}
          </Step>
          <Step>
            <span>03</span>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.{" "}
            </P>
            {/* <ul>
                            <li>- Help you decide which offer is better - but
                                you’ll be making the final decision.</li>
                            <li>- 60 minutes Creditability Interview practice
                                on Zoom.</li>
                            <li>- Chase up your CAS(visa letter).</li>
                            <li>- Help fill the visa application form and
                                booking your biometric appointment.</li>
                        </ul> */}
          </Step>
        </Steps>
      </Container>
    </Section>
  );
}
