import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../styles/Button.styled";
import { H2, P } from "../../styles/Fonts.styled";
import {
  ButtonWrapper,
  Field,
  FormWrapper,
  Input,
  Label,
} from "../../styles/Form.styled";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { device } from "../../styles/devices";

const TextareaField = styled(Field)`
  width: 100%;
  margin: 10px 50px;

  @media ${device.mobile} {
    margin: 0;
  }
`;

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  border: 1px solid rgb(213, 213, 213);
  height: 80px;
  padding: 5px 10px;
  font-family: "Poppins";
  font-size: ${({ theme }) => theme.textSize.xs};
`;

const ButtonPosition = styled(ButtonWrapper)`
  justify-content: flex-end;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

export default function ExperienceModal(props) {
  const [formState, setFormState] = useState({
    title: "",
    employer: "",
    startDate: null,
    endDate: null,
    description: "",
    current: false,
  });

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { title, employer, startDate, endDate, description, current } =
      formState;

    props.setState("experiences", [
      ...props.getState("experiences", []),
      {
        title,
        employer,
        startDate,
        endDate,
        description,
        current,
      },
    ]);
    props.onClose();
    setFormState({
      title: "",
      employer: "",
      startDate: null,
      endDate: null,
      description: "",
      current: false,
    });
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        closeOnOverlayClick={true}
        center
      >
        <H2 center>Add Your Previous Education</H2>
        <P>Start it from the recent ones</P>
        <FormWrapper>
          <Field>
            <Label>Job Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={formState.title}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>Employerr</Label>
            <Input
              type="text"
              name="employer"
              id="employer"
              value={formState.employer}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={formState.startDate || ""}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={formState.endDate || ""}
              onChange={handleChange}
            />
          </Field>

          <TextareaField>
            <Label>Job Description</Label>
            <Textarea
              id="description"
              name="description"
              value={formState.description}
              onChange={handleChange}
            ></Textarea>
          </TextareaField>
        </FormWrapper>
        <ButtonPosition>
          <Button onClick={handleSubmit}>Add</Button>
        </ButtonPosition>
      </Modal>
    </>
  );
}
