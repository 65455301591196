import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import ContentSection from "../../components/discover/ContentSection";
import { H1 } from "../../components/styles/Fonts.styled";
import styled from "styled-components";
import Container from "../../components/styles/Container.styled";
import { Center, Section } from "../../components/styles/Section.styled";
import { device } from "../../components/styles/devices";
import TravelCard from "../../components/discover/TravelCard";
import University from "../../components/discover/University";
import Loading from "../../components/loading/Loading";

const Content = styled(Center)`
  /* height: 60vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;

  @media ${device.mobile} {
    height: 40vh;
  }
`;

const Heading = styled(H1)`
  color: white;
  margin: 0 20px;
`;

const Head = styled(Section)`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 60vh; */
`;

const Header = ({ discover }) => {
  // console.log("im the link =======>>", discover.cover);
  return (
    <Head bg={discover.cover}>
      <Container>
        <Content>
          <Heading center> Lets Discover {discover.title}</Heading>
        </Content>
      </Container>
    </Head>
  );
};

export default function DiscoverId() {
  const [discover, setDiscover] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    setIsLoading(true);
    sanityClient
      .fetch(
        `*[_type == 'discover' && slug.current == '${slug}']{
          title,
          slug,
          about,
          accomodation,
          jobs,
          travel,
          transport,
          "university": *[_type=='university' && references(^._id)]{ name, Link ,"image": cover.asset->url, slug },
          "cover": coverImage.asset->url,
        }`
      )
      .then((data) => setDiscover(data[0]))
      .catch(console.error);
    setIsLoading(false);
  }, [slug]);

  console.log(discover);

  if (isLoading || !discover) return <Loading />;

  return (
    <>
      <Header discover={discover} />

      {discover.about && (
        <ContentSection
          title={`About ${discover.title}`}
          content={discover.about}
        />
      )}
      {discover.university && (
        <University universities={discover.university} title={discover.title} />
      )}

      {discover.accomodation && (
        <ContentSection
          title={`Accomodation`}
          content={discover.accomodation}
        />
      )}

      {discover.jobs && (
        <ContentSection title={`Jobs`} content={discover.jobs} />
      )}

      {discover.transport && (
        <ContentSection title={`Transport`} content={discover.transport} />
      )}

      {discover.travel && <TravelCard content={discover.travel} />}
    </>
  );
}
