import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}
h1{
    margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  padding: 0;
}

p {
    margin: 0;
    line-height: 1.6;
}

img {
    max-width: 100%;
}

`;

export default GlobalStyles;
