import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { H2 } from "../../../components/styles/Fonts.styled";
import "react-responsive-modal/styles.css";
import SideBarModal from "./SideBarModal";

const Right = styled.div`
  width: 30%;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  box-shadow: 0px 6px 10px #00000029;
  padding: 1.25rem 1.5rem;
`;

const SubHeading = styled(H2)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
`;

const Block = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  :last-child {
    padding-bottom: 0;
  }
`;

const Info = styled.p`
  font-size: 14px;
  font-weight: 300;
`;

const H3 = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const Uni = styled(Link)`
  font-size: 14px;
  font-weight: 300;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default function SideBar({ user, isSameUser }) {

  // console.log("user from side bar", user);

  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <Right>
      <SideBarModal
        open={open}
        onClose={closeModal}
        user={user}
        // getState={props.getState}
        // setState={props.setState}
      />
      <SubHeading>
        Basic details
        {isSameUser && <span onClick={openModal}>Edit</span>}
      </SubHeading>

      <Block>
        <H3>University:</H3>
        {user.universitySlug && (
          <Uni to={`/universities/${user.universitySlug}`}>
            {user.university}
          </Uni>
        )}
      </Block>
      <Block>
        <H3>Degree:</H3>
        <Info>{user.degree}</Info>
      </Block>
      <Block>
        <H3>Degree Program:</H3>
        <Info>{user.degreeType}</Info>
      </Block>
      <Block>
        <H3>Currently as a Student:</H3>
        <Info>{user.currentlyAsAStudent ? "Yes" : "No"}</Info>
      </Block>
    </Right>
  );
}
