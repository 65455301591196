import React, { useState, useEffect } from "react";
import Container from "../../components/styles/Container.styled";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import Loading from "../../components/loading/Loading";
import Header from "./Components/Header";
import SideBar from "./Components/SideBar";
import MainContent from "./Components/MainContent";
import ExploreMore from "./Components/ExploreMore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 50px 0;
`;

export default function Profile() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [isSameUser, setIsSameUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProfile = async (id) => {
    setIsLoading(true);
    await sanityClient
      .fetch(
        `*[_type == 'users' && _id == '${id}']{
      "id": _id,
      bio,
      currentlyAsAStudent,
      degree,
      degreeType,
      "image": image.asset->url,
      interests,
      name,
      postcode,
      phoneNumber,
      skills,
      username,
      "city": city->title,
      "cityId": city->_id,
      "university": university->name,
      "universitySlug": university->slug.current,
      "universityId": university->_id,
    }`
      )
      .then((res) => {
        setUser(res[0]);
        setIsLoading(false);
      })
      .catch(console.error());
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProfile(id);
  }, [id]);

  const parseUserFromStorage = () => {
    const user = localStorage.getItem("user");
    if (user) {
      const obj = JSON.parse(user);
      const localUserId = obj.sub;
      return localUserId;
    }
    return null;
  };

  // check if its the same user and allow edit profile option
  const editProfile = async (user) => {
    const localUser = parseUserFromStorage();

    if (localUser) {
      const stateUserId = await user?.id;

      if (stateUserId) {
        if (stateUserId === localUser) {
          setIsSameUser(true);
        }
        return null;
      }
    }
  };

  useEffect(() => {
    editProfile(user);
  });

  if (isLoading || !user) return <Loading />;

  return (
    <>
      <Header user={user} isSameUser={isSameUser} />
      <Container>
        <Wrapper>
          <SideBar user={user} isSameUser={isSameUser} />
          <MainContent user={user} isSameUser={isSameUser} />
        </Wrapper>
      </Container>
      <ExploreMore />
    </>
  );
}
