import styled from "styled-components";

export const Button = styled.button`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10px 20px;
  color: ${({ theme }) => theme.colors.lightText};
  border: none;
  border-radius: 5px;
  font-size: ${({ theme }) => theme.textSize.sm};
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 0.1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const WhiteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.lightText};
    border: 1px solid ${({ theme }) => theme.colors.lightText};
  }
`;
