import React, { useState } from "react";
import { Button } from "../../styles/Button.styled";
import { H2, P } from "../../styles/Fonts.styled";
import { Field, FormWrapper, Input, Label } from "../../styles/Form.styled";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export default function EducationModal(props) {
  const [formState, setFormState] = useState({
    degree: "",
    school: "",
    location: "",
    startDate: null,
    endDate: null,
    current: false,
  });

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { degree, school, location, startDate, endDate, current } = formState;

    props.setState("educations", [
      ...props.getState("educations", []),
      {
        degree,
        school,
        location,
        startDate,
        endDate,
        current,
      },
    ]);

    // console.log('Before on close', props.state)
    props.onClose();

    setFormState({
      degree: "",
      school: "",
      location: "",
      startDate: null,
      endDate: null,
      current: false,
    });
  };

  // console.log(formState)

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.onClose}
        closeOnOverlayClick={true}
        center
      >
        <H2 center>Add Your Previous Education</H2>
        <P>Start it from the recent ones</P>
        <FormWrapper>
          <Field>
            <Label>School</Label>
            <Input
              type="text"
              name="school"
              id="school"
              value={formState.school}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>Degree / Program</Label>
            <Input
              type="text"
              name="degree"
              id="degree"
              value={formState.degree}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>City</Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={formState.city}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>Country</Label>
            <Input
              type="text"
              name="country"
              id="country"
              value={formState.country}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>Start Date</Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              value={formState.startDate || ""}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Label>End Date</Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              value={formState.endDate || ""}
              onChange={handleChange}
            />
          </Field>

          {/* <TextareaField>
                        <Label>Job Description</Label>
                        <Textarea
                            id='description'
                            name='description'
                            value={formState.description}
                            onChange={handleChange}
                        >
                        </Textarea>
                    </TextareaField> */}
        </FormWrapper>
        <ButtonContainer>
          <Button onClick={handleSubmit}>Add</Button>
        </ButtonContainer>
      </Modal>
    </>
  );
}
