import React, { useState } from "react";
// import { PortableText } from "@portabletext/react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
// import { Button } from "../../../components/styles/Button.styled";
import { H2, P } from "../../../components/styles/Fonts.styled";
import { AboutModal, InterestModal, SkillModal } from "./MainContentModal";

const Left = styled.div`
  width: 70%;
  /* padding: 0; */
`;

const About = styled.div`
  padding: 20px;
  /* background-color: #eee; */
  margin: 0 0 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px #00000029;
`;

const Tag = styled.span`
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 4px 8px;
  border-radius: 2px;
  margin-right: 5px;
`;

const SubHeading = styled(H2)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
`;

// const myPortableTextComponents = {
//   block: {
//     // Customize block types with ease
//     h1: ({ children }) => <SubHeading>{children}</SubHeading>,
//     normal: ({ children }) => (
//       <p style={{ whiteSpace: "pre-line", paddingBottom: 10 }}>{children}</p>
//     ),
//   },
// };

export default function MainContent({ user, isSameUser }) {
  const [openAbout, setOpenAbout] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);

  const openAboutModal = () => setOpenAbout(true);
  const closeAboutModal = () => setOpenAbout(false);

  const openSkillModal = () => setOpenSkill(true);
  const closeSkillModal = () => setOpenSkill(false);

  const openInterestModal = () => setOpenInterest(true);
  const closeInterestModal = () => setOpenInterest(false);

  return (
    <>
      <Left>
        <AboutModal open={openAbout} onClose={closeAboutModal} user={user}/>
        <About>
          <SubHeading>
            About{isSameUser && <span onClick={openAboutModal}>Edit</span>}
          </SubHeading>
          <P>{user.bio || "No bio yet"}</P>
          {/* <PortableText
            value={user.bio || "No bio yet"}
            components={myPortableTextComponents}
          /> */}
        </About>
        <About>
          <InterestModal
            open={openInterest}
            onClose={closeInterestModal}
            user={user}
          />
          <SubHeading>
            Interest
            {isSameUser && <span onClick={openInterestModal}>Add new</span>}
          </SubHeading>

          {user.interests &&
            user.interests.map((interest, index) => (
              <Tag key={index}>{interest}</Tag>
              // <P key={index}>{interest}</P>
            ))}
        </About>
        <About>
          <SkillModal open={openSkill} onClose={closeSkillModal} user={user} />
          <SubHeading>
            Skills {isSameUser && <span onClick={openSkillModal}>Add new</span>}
          </SubHeading>
          {user.skills &&
            user.skills.map((skill, index) => <Tag key={index}>{skill}</Tag>)}
        </About>
      </Left>
    </>
  );
}
