import React from "react";
import Container from "../styles/Container.styled";
import { Button } from "../styles/Button.styled";
import { H1 } from "../styles/Fonts.styled";
import {
  ButtonWrapper,
  Field,
  FormWrapper,
  Input,
  Label,
  Wrapper,
} from "../styles/Form.styled";
import styled from "styled-components";
import { device } from "../styles/devices";

const TextareaField = styled(Field)`
  width: 100%;
  margin: 10px 50px;

  @media ${device.mobile} {
    margin: 0;
  }
`;
const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  border: 1px solid rgb(213, 213, 213);
  height: 80px;
  padding: 5px 10px;
  font-family: "Poppins";
  font-size: ${({ theme }) => theme.textSize.xs};
`;

const ButtonPosition = styled(ButtonWrapper)`
  justify-content: flex-end;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

export default function StepOne(props) {
  const onBTNClickNext = (e) => {
    e.preventDefault();
    props.next();
  };

  // console.log(props.state)

  return (
    <div>
      <Container>
        <Wrapper>
          <H1 center>{props.title}</H1>
          {/* <H2 center>Initial Infromation</H2> */}
          <FormWrapper>
            <Field>
              <Label>First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={props.getState("firstName") || ""}
                onChange={props.handleChange}
              />
            </Field>
            <Field>
              <Label>last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={props.getState("lastName") || ""}
                onChange={props.handleChange}
              />
            </Field>
            <Field>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={props.getState("email") || ""}
                onChange={props.handleChange}
              />
            </Field>
            <Field>
              <Label>Phone number</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                value={props.getState("phone") || ""}
                onChange={props.handleChange}
              />
            </Field>
            <TextareaField>
              <Label>Summary about yourself</Label>
              <Textarea
                id="summary"
                name="summary"
                value={props.getState("summary") || ""}
                onChange={props.handleChange}
              ></Textarea>
            </TextareaField>
          </FormWrapper>
          <ButtonPosition>
            {/* <Button onClick={onBTNClickNextPrev}>Prev</Button> */}
            <Button onClick={onBTNClickNext}>Next</Button>
          </ButtonPosition>
        </Wrapper>
      </Container>
    </div>
  );
}
