import React from "react";
import styled from "styled-components";
import { device } from "../../../components/styles/devices";

const Fixed = styled.div`
  position: fixed;
  bottom: 0;
  background: #262260;

  width: 100%;
  padding: 15px 20px;
  z-index: 999;
  @media ${device.mobile} {
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const P = styled.p`
  font-size: 13px;
  color: white;
  flex: 1;
  font-weight: 300;
`;

const Button = styled.a`
  background-color: white;
  color: #5046e5;
  text-align: center;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;

  &:hover {
    background-color: #5046e5;
    color: white;
  }
`;

const URL =
  "https://docs.google.com/forms/d/e/1FAIpQLSfEZEucnaxDYF9i9n8x3FN4kryF3IZwSvm2mnfCNgVOF969YA/viewform";

export default function BottomBar() {
  return (
    <Fixed>
      <Wrapper>
        <P>Want to study abroad? Let us help you make it happen.</P>
        <Button href={URL} target="_blank">
          Talk to an expert
        </Button>
      </Wrapper>
    </Fixed>
  );
}
