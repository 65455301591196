import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import styled from "styled-components";

const Section = styled.div`
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Animation = styled.div`
  width: 500px;
`;

export default function Loading() {
  return (
    <Section>
      <Animation>
        <Player autoplay loop speed={0.3} src="./loading.json"></Player>
      </Animation>
    </Section>
  );
}
