import styled from "styled-components";
import { device } from "./devices";

export const Wrapper = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 70px auto;
  box-shadow: 0px 6px 10px #00000029;
  border-radius: 15px;
  padding: 50px 80px;
  background-color: ${({ theme }) => theme.colors.secondary};

  @media ${device.mobile} {
    max-width: 90%;
    margin: 20px auto;
    padding: 20px 25px;
  }
`;

export const Input = styled.input`
  padding: 5px 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid rgb(213, 213, 213);
  border-radius: 5px;
`;
export const Label = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`;
export const Field = styled.div`
  
  display: flex;
  flex: 50%;
  flex-direction: column;
  padding: 10px;

  @media ${device.mobile} {
    width: 100%;
  }
`;
export const FormWrapper = styled.div`
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
export const ButtonWrapper = styled.div`
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
`;
