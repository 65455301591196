import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import styled from "styled-components";
import Container from "./styles/Container.styled";
import { H2 } from "./styles/Fonts.styled";

const Section = styled.div`
  padding: 0;
`;

const Card = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  /* filter: grayscale(1); */
  max-height: 150px;
`;
export default function Slider() {
  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        perView: 6,
        spacing: 15,
      },
      breakpoints: {
        "(min-width: 300px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 768px)": {
          slides: { perView: 6, spacing: 10 },
        },
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 1000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <Section>
      <Container>
        <H2 style={{ paddingBottom: 25, textAlign: "center" }}>
          Our Pakistan student community is spread across UK Universities
        </H2>
        <div
          ref={sliderRef}
          className="keen-slider"
          style={{ display: "flex", alignContent: "center" }}
        >
          <Card className="keen-slider__slide">
            <Image src="/unis/Aston.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/BPP.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Brunel.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/uoc_logo_black.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Coventry.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Derby.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Dundee.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Edinburgh.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/LSBU.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/MMU.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/QMUL.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/QUB.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Salford.png" />
          </Card>
          <Card className="keen-slider__slide">
            <Image src="/unis/Uclan.png" />
          </Card>
        </div>
      </Container>
    </Section>
  );
}
