import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import Container from "../../components/styles/Container.styled";
// import { P } from "../../components/styles/Fonts.styled";
// import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";
// import { Button } from "../../components/styles/Button.styled";
import Loading from "../../components/loading/Loading";
import UniHeader from "./Components/UniHeader";
import SideBar from "./Components/SideBar";
import MainContent from "./Components/MainContent";

// const Head = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 100%;
// `;
// const UniImage = styled.div`
//   width: 30%;
// `;
// const UniTitle = styled.div`
//   width: 70%;
//   padding-left: 30px;
// `;
// const Image = styled.img`
//   width: 100%;
//   border-radius: 20px;
// `;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   align-items: flex-start;
// `;
// const Card = styled.div`
//   width: calc(25% - 20px);
//   padding: 20px;
//   margin: 10px;
// `;

// const Para = styled(P)`
//   margin: 0;
// `;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 0;
  gap: 10px;
  align-items: flex-start;
`;

export default function UniversityPage() {
  const { slug } = useParams();
  const [university, setUniversity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await sanityClient
        .fetch(
          `*[_type == 'university' && slug.current == '${slug}']{
            name,
            "cover": cover.asset->url,
            link,
            deposit,
            englishRequirements,
            scholarship,
            campuses,
            intakes,
            accomodation,
            "users": *[_type == 'users' && references(^._id)]{
              _id,
              "userImage": image.asset->url,
              name,
            }
        }`
        )
        .then((res) => {
          setUniversity(res[0]);
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
    };

    fetchData();
  }, [slug]);

  if (isLoading || !university) return <Loading />;

  return (
    <>
      <UniHeader university={university} />
      <Container>
        <ContentWrapper>
          <SideBar university={university} />
          <MainContent university={university} />
        </ContentWrapper>
      </Container>
    </>
  );
}
