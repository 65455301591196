import React, { useState } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { device } from "./styles/devices";

export default function ScrollButton() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", toggleVisible);

  const Button = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: #4f46e5;
    border-radius: 20px;
    color: white;
    position: fixed;
    left: 94%;
    bottom: 8%;
    z-index: 1;
    cursor: pointer;
    span {
      margin-left: 5px;
    }

    @media ${device.mobile} {
      left: 80%;
    }
  `;

  return (
    <Button
      onClick={scrollToTop}
      style={{ display: visible ? "flex" : "none" }}
    >
      <BsFillArrowUpCircleFill />
      <span>Top</span>
    </Button>
  );
}
