import React from "react";
import ScrollButton from "../../components/ScrollButton";
import BlogCard from "./Components/BlogCard";
import Header from "./Components/Header";

export default function Guide() {
  return (
    <>
      <Header />
      <BlogCard />
      <ScrollButton />
    </>
  );
}
