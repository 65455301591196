import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "./devices";

export const Nav = styled.div`
  width: 100%;
  background-color: #f9f9f9;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  flex-basis: 100%;
  flex-wrap: wrap;

  @media ${device.mobile} {
    padding: 10px 0;
  }
`;

export const Logo = styled.img`
  width: 220px;
  /* height: 40px; */
  display: flex;
`;
export const Items = styled.p`
  margin: 0 10px;
  color: ${({ theme }) => theme.colors.darkText};
  font-size: ${({ theme }) => theme.textSize.sm};
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledMenu = styled.button`
  display: none;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    background-color: inherit;
  }
`;

export const Hamburger = styled.span`
  width: 35px;
  height: 3px;
  background-color: black;
  border-radius: 4px;
  margin: 2px 0;
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  &:active {
    height: 275px;
    transition: height 0.35s ease;
  }
`;

export const ProfileIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 50%;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  position: relative;
  z-index: 999;
`

export const ProfileDropdown = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  top: 40px;
  /* right: 0;
  left: 0; */
  width: 140px;
`
export const ProfileDropdownItem = styled(Link)`
  color: white;
  padding: 10px;
  width: 100%;
`
