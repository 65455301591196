import { Player } from "@lottiefiles/react-lottie-player";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Facebook from "../../components/Facebook";
// import Form from "../../components/Form";
import Slider from "../../components/Slider";
import Container from "../../components/styles/Container.styled";
import { device } from "../../components/styles/devices";
import { H1, P } from "../../components/styles/Fonts.styled";
import { Center, Section } from "../../components/styles/Section.styled";
import Card from "../../components/templates/Card";

import sanityClient from "../../client";

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media ${device.mobile} {
    flex-direction: column;
  }
`


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${device.tablet}{
    flex-direction: row;
  } 


`;

const Head = styled(Center)`
  height: 50vh;
  align-items: flex-start;
  max-width: 600px;
  flex: 50%;
  @media ${device.tablet},  ${device.laptop}{
    margin: 0;
    flex: 50%;
  }
  @media ${device.mobile} {
    height: auto;
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Animation = styled.div`
  width: 500px;

  @media ${device.tablet}{
    flex: 50%

  }

  @media ${device.mobile} {
    width: 100%;
    padding: 0 20px;
  }
`;



const Header = () => {
  return (
    <Section gray>
      <Container>
        <HeadWrapper>
          <Head>
            <H1>
              Documents that you might
              <br /> <span>Need when applying</span>
            </H1>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation{" "}
            </P>
          </Head>
          <Animation>
            <Player autoplay loop speed={0.3} src="./find.json"></Player>
          </Animation>
        </HeadWrapper>
      </Container>
    </Section>
  );
};

const CardSection = ({ templates }) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          {templates &&
            templates.map((template, index) => {
              return <Card template={template} key={index} />;
            })}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default function Templates() {
  const [templates, setTemplates] = useState(null);

  const loadData = async () => {
    const res = await sanityClient.fetch`*[_type == 'template']{
      title,
      slug,
      excerpt,
      "document": document.asset->url,
    }`;
    setTemplates(await res);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Header />
      <CardSection templates={templates} />
      {/* <Form /> */}
      <Slider />
      <Facebook />
    </>
  );
}
