import React from "react";
import { H2, P } from "../styles/Fonts.styled";
import { Button } from "../styles/Button.styled";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../styles/devices";

const Wrapper = styled.div`
  flex: 30%;
  margin: 20px;
  padding: 30px 40px;
  box-shadow: 0px 6px 10px #00000029;
  border-radius: 15px;

  @media ${device.tablet} {
    flex: 50%;
  }

  @media ${device.mobile} {
    flex: 100%;
    margin: 10px;
    padding: 20px 30px;
  }
`;

const Download = styled.a`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 9px 20px;
  color: ${({ theme }) => theme.colors.lightText};
  border: none;
  border-radius: 5px;
  font-size: ${({ theme }) => theme.textSize.sm};
  cursor: pointer;
  margin-right: 10px;
  /* z-index: 1; */
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 0.1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const Description = styled(P)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export default function Card({ template }) {
  // const {templateId} = useParams()

  console.log("TEMPLATE ========>>", template);

  return (
    <Wrapper>
      <H2 style={{ paddingBottom: 10, paddingTop: 10 }}>{template.title}</H2>
      <Description>{template.excerpt}</Description>
      <Download href={template.document} target="_blank" download>
        Download
      </Download>
      <Link to={template.slug.current}>
        <Button>View</Button>
      </Link>
    </Wrapper>
  );
}
