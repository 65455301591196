import React from "react";
import styled from "styled-components";

const SkillContainer = styled.li`
  font-size: 12px;
  letter-spacing: -0.03em;
  font-weight: 400;
  margin-bottom: 22px;
  padding: 0;
  /* margin: 0; */
`;

const Marker = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #46464b;
  margin-right: 8px;
  display: inline-block;
`;

const Skill = (prop) => {
  return (
    <SkillContainer>
      <Marker />
      {prop.skill}
    </SkillContainer>
  );
};

export default Skill;
