import React from "react";
import styled from "styled-components";
import { WhiteButton } from "./styles/Button.styled";
import Container from "./styles/Container.styled";
import { device } from "./styles/devices";
import { H1 } from "./styles/Fonts.styled";
// import { Section } from "./styles/Section.styled";

const groupUrl = "https://www.facebook.com/groups/ukstudyguide/";

const Heading = styled(H1)`
  margin: 0;
  z-index: 1;

  @media ${device.mobile} {
    padding-bottom: 10px;
  }
`;

const Button = styled(WhiteButton)`
  text-align: center;
`;


const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  background-color: #4338ca;
  padding: 50px 70px;
  z-index: 1;
  margin: 0 auto;
  height: 250px;
  border-radius: 15px;
  overflow: hidden;

  @media ${device.mobile} {
    height: 300px;
    width: 100%;
  }
`;
const Image = styled.img`
  position: absolute;
  width: 600px;
  right: -15px;
  bottom: -10px;
  opacity: 0.2;

  @media ${device.mobile} {
    width: 285px;
    bottom: -3px;
    right: -10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  /* z-index: 999; */
  h1 {
    font-size: ${({ theme }) => theme.textSize.xl};
    color: #fff;
  }

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
    h1 {
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;

export default function Facebook() {
  return (
    // <Section>
      <Container>
        <Wrapper>
          <Content>
            <Heading style={{ margin: 0 }}>JOIN OUR COMMUNITY</Heading>
            <Button as={"a"} href={groupUrl} target="_blank">
              Join now
            </Button>
          </Content>
          <div>
            <Image src="/images/facebook-black@2x.png" alt="" />
          </div>
        </Wrapper>
      </Container>
    // </Section>
  );
}
