import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import { Section } from "../../components/styles/Section.styled";
import Container from "../../components/styles/Container.styled";

// import PortableText from "@sanity/block-content-to-react";
import Header from "../../components/guide/innerPage/Header";
import styled from "styled-components";
import Loading from "../../components/loading/Loading";
import { PortableText } from "@portabletext/react";
import { H2 } from "../../components/styles/Fonts.styled";
import OtherBlog from "./Components/OtherBlog";

const Wrapper = styled(Container)`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
`;

export default function GuideBlog({ blog }) {
  let { slug } = useParams();

  const [singleBlog, setSingleBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSingleBlog = async (slug) => {
    await sanityClient
      .fetch(
        `*[_type == "blog" && slug.current == "${slug}"]{
      title,
      body,
      "authorName": author->name,
      "authorImage": author->image.asset->url,
      "coverImage": cover.asset->url,
      "authorBio": author->bio,
      _createdAt,
      "categoryName": category[0]->description,
      "previousBlog": *[_type == "blog" && ^._createdAt > _createdAt][0]{
        "slug": slug.current,
        title,
        excerpt,
        "authorImage": author->image.asset->url,
        "authorName": author->name,
        "coverImage": cover.asset->url,
        _createdAt,
      },
      "nextBlog": *[_type == "blog" && ^._createdAt < _createdAt][0]{
        "slug": slug.current,
        title,
        excerpt,
        "authorImage": author->image.asset->url,
        "authorName": author->name,
        "coverImage": cover.asset->url,
        _createdAt,
      },
    }`
      )
      .then((res) => {
        setSingleBlog(res[0]);
      })
      .catch((err) => console.error(err));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleBlog(slug);
  }, [slug]);

  if (isLoading || !singleBlog) return <Loading />;
  return (
    <div>
      <Header
        title={singleBlog.title}
        authorName={singleBlog.authorName}
        authorImage={singleBlog.authorImage}
        _createdAt={singleBlog._createAt}
        categoryName={singleBlog.categoryName}
        coverImage={singleBlog.coverImage}
      />
      <Section>
        <Wrapper>
          {singleBlog.body && (
            <PortableText
              value={singleBlog.body}
              components={{
                block: {
                  // Customize block types with ease
                  h1: ({ children }) => <H2>{children}</H2>,
                  normal: ({ children }) => (
                    <p style={{ whiteSpace: "pre-line", paddingBottom: 10 }}>
                      {children}
                    </p>
                  ),
                },
              }}
            />
          )}
        </Wrapper>
      </Section>
      <OtherBlog
        nextBlog={singleBlog.nextBlog}
        prevBlog={singleBlog.previousBlog}
      />
    </div>
  );
}
