import React, { useState } from "react";
import Container from "../../components/styles/Container.styled";
import { H1, P } from "../../components/styles/Fonts.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";

const Head = styled.div`
  padding: 30px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// const Exchange = styled.div`
//   padding: 40px;
//   box-shadow: 0px 6px 10px #00000029;
//   border-radius: 15px;
//   text-align: center;
//   margin-right: 100px;
// `;

const Wrapper = styled.div``;

const FieldSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ResultSection = styled.div`
  flex: 50%;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Form = styled.form`
  flex: 50%;
`;

const Input = styled.input`
  padding: 10px 15px;
  font-size: 20px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const Select = styled.select`
  padding: 10px 15px;
  font-size: 20px;
`;

const Amount = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;
const Title = styled.p``;
const Price = styled.p``;

const Line = styled.span`
  width: 100%;
  border-bottom: 1px dashed #707070;
`;
const Header = () => {
  return (
    <Head>
      {/* <div> */}
      <H1> Calculator </H1>
      <P>
        This will give you a rough estimate of what you need. You’re required to
        double check everything before making your decision.
      </P>
      {/* </div> */}
      {/* <Exchange>
        <P>Today's Rate</P>
        <H2>237.32</H2>
      </Exchange> */}
    </Head>
  );
};

const CalculateSection = () => {
  const [totalFee, setTotalFee] = useState();
  const [initialPayment, setInitialPayment] = useState();
  const [poundRate, setPoundRate] = useState();
  const [location, setLocation] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  // const [predictedAmount, setPredictedAmount] = useState(0);
  // const [dependant, setDependant] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();

    const remainingFee = totalFee - initialPayment;
    const amountToHold = remainingFee + location;
    // const poundAmount = poundRate * amountToHold;
    // const prediction = predictedAmount * amountToHold;

    setTotalAmount(amountToHold);
  };

  return (
    <Section>
      <Container>
        <Wrapper>
          <H1 center>BANK STATEMENT CALCULATOR</H1>
          <FieldSection>
            <Form onSubmit={handleSubmit}>
              <Label>
                Total Fee (e.g: £12,000)
                <Input
                  type="number"
                  value={totalFee}
                  onChange={(e) => setTotalFee(Number(e.target.value))}
                  required
                />
              </Label>
              <Label>
                Initial Payment (e.g: £5,000)
                <Input
                  type="number"
                  value={initialPayment}
                  onChange={(e) => setInitialPayment(Number(e.target.value))}
                  required
                />
              </Label>
              <Label>
                Location
                <Select
                  value={location}
                  onChange={(e) => setLocation(Number(e.target.value))}
                >
                  <option disabled selected>
                    Select location
                  </option>

                  <option value="12005">London</option>
                  <option value="9207">Outside London</option>
                </Select>
              </Label>
              {/* {location.london && (
                <Label>
                  Number of dependant(if any) 0-5
                  <select
                    value={location}
                    onChange={(e) => setLocation(Number(e.target.value))}
                  >
                    <option value="7605">0</option>
                    <option value="12005">1</option>
                    <option value="12005">2</option>
                    <option value="12005">3</option>
                    <option value="12005">4</option>
                    <option value="9207">5</option>
                  </select>
                  <Input
                    type="number"
                    min={Number(0)}
                    max={Number(5)}
                    value={dependant}
                    onChange={(e) => setDependant(Number(e.target.value))}
                    required
                  />
                </Label>
              )}
              {location.outsideLondon && (
                <Label>
                  Number of dependant(if any) 0-5
                  <select
                    value={location}
                    onChange={(e) => setLocation(Number(e.target.value))}
                  >
                    <option value="6120">0</option>
                    <option value="12005">1</option>
                    <option value="12005">2</option>
                    <option value="12005">3</option>
                    <option value="12005">4</option>
                    <option value="9207">5</option>
                  </select>
                  <Input
                    type="number"
                    min={Number(0)}
                    max={Number(5)}
                    value={dependant}
                    onChange={(e) => setDependant(Number(e.target.value))}
                    required
                  />
                </Label>
              )} */}

              <Label>
                Pound rate (e.g PKR 200)
                <Input
                  type="number"
                  value={poundRate}
                  onChange={(e) => setPoundRate(Number(e.target.value))}
                  required
                />
              </Label>

              {/* <Label>
                Predecticed amount
                <Input
                  type="number"
                  value={predictedAmount}
                  onChange={(e) => setPredictedAmount(Number(e.target.value))}
                  required
                />
              </Label> */}
              <Input type="submit" value="Submit" />
            </Form>
            <ResultSection>
              <Amount>
                <Title>Total Fee</Title>
                <Price>
                  {" "}
                  {totalFee
                    ? totalFee.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                      })
                    : null}
                </Price>
              </Amount>
              <Amount>
                <Title>Initial Payment</Title>
                <Price>
                  {" "}
                  {initialPayment ? (
                    <P style={{ margin: 0 }}>
                      - (
                      {initialPayment.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                      })}
                      ){" "}
                    </P>
                  ) : null}
                </Price>
              </Amount>
              <Line></Line>
              <Amount>
                <Title>Remaining Fee</Title>
                <Price>
                  {" "}
                  {totalFee && initialPayment
                    ? (totalFee - initialPayment).toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                      })
                    : null}
                </Price>
              </Amount>
              <Amount>
                <Title>Living Cost</Title>
                <Price>
                  {" "}
                  {location ? (
                    <P style={{ margin: 0, color: "black" }}>
                      +{" "}
                      {location.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                      })}
                    </P>
                  ) : null}
                </Price>
              </Amount>
              <Line></Line>
              <Amount>
                <Title>Amount to hold (£££)</Title>
                <Price>
                  {" "}
                  {totalAmount
                    ? totalAmount.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                      })
                    : null}
                </Price>
              </Amount>
              <Amount>
                <Title>Amount to hold (PKR)</Title>
                <Price>
                  {" "}
                  {totalAmount
                    ? (totalAmount * poundRate).toLocaleString("en-GB", {
                        style: "currency",
                        currency: "PKR",
                        minimumFractionDigits: 0,
                      })
                    : null}
                </Price>
              </Amount>
            </ResultSection>
          </FieldSection>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default function FinancialStatementCalculator() {
  // const [rate, SetRate] = useState("")

  // useEffect(() => {

  // }, [])

  // const calculateTotalAmount = () => {

  // }

  return (
    <Section gray>
      <Container>
        <Header />
        <CalculateSection />
      </Container>
    </Section>
  );
}
