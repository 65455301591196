import styled from "styled-components";
import { device } from "./devices";

export const Section = styled.div`
    width: 100%;
    background-color: ${props => props.gray ? props.theme.colors.secondary : "white"};
    padding: ${(props) => (props.variant === 'head' ? "0" : "70px 0")};

    @media ${device.mobile} {
    padding: ${(props) => (props.variant === 'head' ? "0" : "40px 0")};
    }
`
export const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props => props.row ? "row" : "column"};
`
