import React, { useState, useEffect } from "react";
import Container from "../../components/styles/Container.styled";
import { H1 } from "../../components/styles/Fonts.styled";
import { Section } from "../../components/styles/Section.styled";

import sanityClient from "../../client";
import FaqCard from "../../components/faqs/FaqCard";
import Loading from "../../components/loading/Loading";

export default function Faqs() {
  const [faqs, setFaqs] = useState(null);
  // const [faqCategory, setFaqCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'faqCategory']`)
      .then((data) => setFaqs(data))
      .catch(console.error);
      // window..',,;'
      // change the title of the website
      document.title = "Faqs | UK STUDY GUIDE";

    setIsLoading(false);
  }, []);

  console.log(faqs)

  if (isLoading || !faqs) return <Loading />;
  return (
    <>
      <Section gray>
        <Container>
          <H1 center> Common questions asked </H1>
        </Container>
      </Section>
      <FaqCard faqs={faqs} />
    </>
  );
}
