import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../../components/styles/Container.styled";

const Section = styled.div`
  padding-bottom: 30px;
`;

const Heading = styled.h1`
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Card = styled(Link)`
  flex: 45% 0 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 6px 10px #00000029;
  padding: 10px 20px;
  border-radius: 10px;
`;

const Image = styled.img`
  width: 40%;
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  /* padding-right: 0; */
  &:last-child {
    /* padding-left: 0; */
    padding-left: 10px;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const H1 = styled.h1`
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.p`
  font-size: 14px;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50%;
`;

const AuthorName = styled.h3`
  margin: 0;
  font-size: 14px;
`;

export default function OtherBlog({ nextBlog, prevBlog }) {
  return (
    <Section>
      <Container>
        <Heading>Other Guides</Heading>
        <Wrapper>
          {prevBlog && (
            <Card to={`/guide/${prevBlog.slug}`}>
              <Image src={prevBlog.coverImage} />
              <Content>
                <Text>
                  <H1>{prevBlog.title}</H1>
                  <Description>{prevBlog.excerpt}</Description>
                </Text>
                <Author>
                  <Avatar src={prevBlog.authorImage} />
                  <Text>
                    <AuthorName>{prevBlog.authorName}</AuthorName>
                    <Date>
                      {moment(prevBlog._createdAt).format("MMMM D, YYYY")}
                    </Date>
                  </Text>
                </Author>
              </Content>
            </Card>
          )}
          {nextBlog && (
            <Card to={`/guide/${nextBlog.slug}`}>
              <Content>
                <Text>
                  <H1>{nextBlog.title}</H1>
                  <Description>{nextBlog.excerpt}</Description>
                </Text>
                <Author>
                  <Avatar src={nextBlog.authorImage} />
                  <Text>
                    <AuthorName>{nextBlog.authorName}</AuthorName>
                    <Date>
                      {moment(nextBlog._createdAt).format("MMMM D, YYYY")}
                    </Date>
                  </Text>
                </Author>
              </Content>
              <Image src={nextBlog.coverImage} />
            </Card>
          )}
        </Wrapper>
      </Container>
    </Section>
  );
}
