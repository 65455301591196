import React from "react";
import styled from "styled-components";
import { getYear } from "../../utils/date";

const EducationContainer = styled.div`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 35px;
  }
`;
const EducationTitle = styled.h4`
  letter-spacing: -0.03em;
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 500;
  margin: 0;
  /* padding-bottom: 7px; */
  color: ${({ theme }) => theme.colors.darkText};
`;
const School = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;
const EducationDuration = styled.h6`
  color: ${({ theme }) => theme.colors.lightGrey};
  margin: 5px 0;
  font-size: 12px;
  letter-spacing: -0.03em;
  font-weight: 500;

  /* margin-bottom: 18px; */
`;
const EducationDesc = styled.p`
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkText};
`;
const Education = (props) => {
  const { education } = props;

  // console.log(education);

  // function to render Education endDate
  const renderEndDate = () => {
    if (education.endDate) {
      return Date.parse(education.endDate)
        ? getYear(education.endDate)
        : education.endDate;
    } else {
      return "endDate";
    }
  };

  // degree
  // school
  // location

  return (
    <EducationContainer>
      <EducationTitle>
        <School>{education.school || "School"} -</School>{" "}
        {education.degree || "Degree title"}
      </EducationTitle>
      <EducationDuration>
        {education.startDate ? getYear(education.startDate) : "startDate"} -{" "}
        {renderEndDate()}
      </EducationDuration>
      <EducationDesc>
        {education.city || "city"} - {education.country || "country"}
      </EducationDesc>
    </EducationContainer>
  );
};

export default Education;
