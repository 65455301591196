import React from "react";
import Container from "../../components/styles/Container.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";
// import sanityClient from "../../client";
// import SettingForm from "./SettingForm";
// import Loading from "../../components/loading/Loading";
// import Input from "./Input";

const H1 = styled.h1`
  margin: 0;
  font-size: 32px;
`;

const P = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 300;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default function Settings() {
  return (
    <>
      <Section>
        <Container>
          <Head>
            <H1>Account Settings</H1>
            <P>Comming Soon</P>
          </Head>
        </Container>
      </Section>
      {/* <SettingForm /> */}
    </>
  );
}
