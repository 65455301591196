import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  Logo,
  Menu,
  Nav,
  Items,
  Wrapper,
  StyledMenu,
  Hamburger,
  MobileMenu,
  ProfileIcon,
  ProfileDropdown,
  ProfileDropdownItem,
} from "./styles/Navbar.styled";
import Container from "./styles/Container.styled";
import { Button } from "./styles/Button.styled";
import { googleLogout } from "@react-oauth/google";
import { FaUserAlt } from "react-icons/fa";
import styled from "styled-components";

const Expand = styled.div`
  position: relative;
`;
const ExpandedMenu = styled.div`
  position: absolute;
  background-color: #4f45e4;
  padding: 10px 5px;
  border-radius: 5px;
  top: 25px;
  min-width: 170px;
  z-index: 999;
`;

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [userDropdown, setUserDropdown] = useState(false);
  const [user, setUser] = useState();
  const [expandMenu, setExpandMenu] = useState({
    learn: false,
    discover: false,
  });

  const navigate = useNavigate();

  const onMouseEnter = (name) => {
    if (window.innerWidth < 960) {
      setExpandMenu((state) => ({ ...state, [name]: false }));
    } else {
      setExpandMenu((state) => ({ ...state, [name]: true }));
    }
  };

  const onMouseLeave = (name) => {
    setExpandMenu((state) => ({ ...state, [name]: false }));
  };

  useEffect(() => {
    async function checkLogin() {
      const user = localStorage.getItem("user");
      if (!user) {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        setUser(JSON.parse(user));
      }
    }
    checkLogin();
  }, [isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    googleLogout();

    setIsLoggedIn(false);
    navigate("/login");
  };

  // if (!user) {
  // } else {
  //   const userId = user.sub;
  // }

  const ChangeLoginButton = () => {
    if (isLoggedIn) {
      return (
        <ProfileIcon onClick={() => setUserDropdown(!userDropdown)}>
          <FaUserAlt />
          {userDropdown && (
            <ProfileDropdown>
              <ProfileDropdownItem to={`/profile/${user.sub}`}>
                Profile
              </ProfileDropdownItem>
              <ProfileDropdownItem to={"/settings"}>
                Settings
              </ProfileDropdownItem>

              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  padding: "5px 10px",
                  fontSize: "12px",
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </ProfileDropdown>
          )}
        </ProfileIcon>
      );
    } else {
      return (
        <Link to={"/login"}>
          <Button>login</Button>
        </Link>
      );
    }
  };

  return (
    <Nav>
      <Container className="container">
        <Wrapper>
          <Link to={"/"}>
            {" "}
            <Logo src="/logo.png" />
          </Link>
          <Menu>
            <NavLink to="/">
              <Items>Home</Items>
            </NavLink>
            <Expand>
              <Items
                onMouseEnter={onMouseEnter.bind(this, "learn")}
                onMouseLeave={onMouseLeave.bind(this, "learn")}
              >
                Learn
              </Items>
              {expandMenu.learn && (
                <ExpandedMenu
                  onMouseEnter={onMouseEnter.bind(this, "learn")}
                  onMouseLeave={onMouseLeave.bind(this, "learn")}
                >
                  <NavLink to="/guide">
                    <Items style={{ color: "white" }}>Guide</Items>
                  </NavLink>
                  <NavLink to="/">
                    <Items style={{ color: "white" }}>Youtube</Items>
                  </NavLink>
                  <NavLink to="/">
                    <Items style={{ color: "white" }}>Facebook</Items>
                  </NavLink>
                </ExpandedMenu>
              )}
            </Expand>
            <Expand>
              <Items
                onMouseEnter={onMouseEnter.bind(this, "discover")}
                onMouseLeave={onMouseLeave.bind(this, "discover")}
              >
                Discover
              </Items>
              {expandMenu.discover && (
                <ExpandedMenu
                  onMouseEnter={onMouseEnter.bind(this, "discover")}
                  onMouseLeave={onMouseLeave.bind(this, "discover")}
                >
                  <NavLink to="/discover">
                    <Items style={{ color: "white" }}>Cities</Items>
                  </NavLink>
                  <NavLink to="/universities">
                    <Items style={{ color: "white" }}>Universities</Items>
                  </NavLink>
                  <NavLink to="/login">
                    <Items style={{ color: "white" }}>Speak to advisor</Items>
                  </NavLink>
                </ExpandedMenu>
              )}
            </Expand>

            <NavLink to="/consultation">
              <Items>Consultation</Items>
            </NavLink>
            <NavLink to="/">
              <Items>Tools</Items>
            </NavLink>
            <NavLink to="/">
              <Items>Faqs</Items>
            </NavLink>

            <ChangeLoginButton />
          </Menu>

          <StyledMenu onClick={() => setIsOpen(!isOpen)}>
            <Hamburger></Hamburger>
            <Hamburger></Hamburger>
            <Hamburger></Hamburger>
          </StyledMenu>
          {isOpen ? <MobileMenu></MobileMenu> : null}
        </Wrapper>
      </Container>
    </Nav>
  );
}
