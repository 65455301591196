import React from "react";
import Container from "../../components/styles/Container.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";
import { H1 } from "../../components/styles/Fonts.styled";
import { Toaster } from "react-hot-toast";
// import { FaGoogle } from "react-icons/fa";
import { GoogleLogin } from "@react-oauth/google";
import client from "../../client";
import { useNavigate } from "react-router-dom";
// import jwt from "jwt-decode";
// import { UserContext } from "../../context/UserContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function Login() {
  const navigate = useNavigate();

  const googleSuccess = async (res) => {
    // console.log("response from google success", res);

    // const decode = jwt(res.credential);
    // console("decodeed", decode)
    fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${res.credential}`)
      .then((res) => res.json())
      .then((response) => {
        const user = response;
        createUser(user);
      })
      .catch((error) => console.log(error));
    // jwtDecode
  };

  const googleError = (error) => {
    console.log("google signin failed-error", error);
  };

  const createUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));

    const { name, email, picture, sub } = user;

    const doc = {
      _id: sub,
      _type: "users",
      name: name,
      email: email,
      image: picture,
    };

    client.createIfNotExists(doc).then(() => {
      navigate(`/profile/${sub}`, { replace: true });
      window.location.reload(true);
      console.log("user created");
    });
  };

  return (
    <Section>
      <Toaster />
      <Container>
        <Wrapper>
          <H1 center>Login</H1>
          <GoogleLogin
            onSuccess={googleSuccess}
            onError={googleError}
            useOneTap
          />
        </Wrapper>
      </Container>
    </Section>
  );
}
