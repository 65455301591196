import React from "react";
import Container from "../styles/Container.styled";
import styled from "styled-components";
import { H2 } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";
import { device } from "../styles/devices";
import PortableText from "@sanity/block-content-to-react";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 70px;
  @media ${device.mobile} {
    margin: 0;
  }
`;

const Card = styled.div`
  width: 45%;
  margin: 20px;

  @media ${device.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`;

export default function FaqCard({ faqs }) {
  return (
    <Section>
      <Container>
        <Wrapper>
          {faqs.map((faq, index) => {
            return (
              <Card key={index}>
                <H2>
                  {index + 1}. {faq.question}
                </H2>
                <PortableText blocks={faq.answer} />
                {/* <P>{faq.answer}</P> */}
              </Card>
            );
          })}
        </Wrapper>
      </Container>
    </Section>
  );
}
