import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Container from "../../components/styles/Container.styled";
import { Section } from "../../components/styles/Section.styled";
import sanityClient from "../../client";

import styled from "styled-components";
import { device } from "../../components/styles/devices";
import { Button } from "../../components/styles/Button.styled";
import Loading from "../../components/loading/Loading";
import Pagination from "react-sanity-pagination";
import ScrollButton from "../../components/ScrollButton";

const Heading = styled.h1`
  font-size: ${({ theme }) => theme.textSize.xl};
  text-align: center;
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.textSize.md};
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-weight: 700;
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.textSize.md};
  }
`;

const P = styled.p`
  font-size: ${({ theme }) => theme.textSize.sm};
  color: ${({ theme }) => theme.colors.darkText};
  width: 100%;
  max-width: 800px;
  margin: 0;
  line-height: 1.2;
`;

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.textSize.md};
  margin: 0;
  line-height: 1.4;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 6px 10px #00000029;
  overflow: hidden;
  margin: 20px 0;
`;

const Left = styled.div`
  width: 30%;
`;

const Image = styled.img`
  width: 100%;
  min-height: 250px;
  margin-bottom: -10px;
  height: 100%;
  object-fit: cover;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 30px;
  justify-content: space-between;
`;

const CardTitle = styled.div``;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const CardText = styled.div`
  width: 33%;
`;

const CardButton = styled.div``;

const SearchBar = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Search = styled.input`
  width: 200px;
  padding: 10px 5px;
  margin: 10px;
`;

const IntakesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const itemsToSend = [];

export default function Universities() {
  const [universities, setUniversities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const postsPerPage = 6;

  console.log(searchTerm);

  const fetchData = async () => {
    await sanityClient
      .fetch(
        `*[_type == 'university']{
          name,
          "cover": cover.asset->url,
          accomodation,
          scholarship,
          intakes,
          deposit,
            "slug": slug.current,
            "cityName": City->title,
      }`
      )
      .then((res) => {
        itemsToSend.push(...res);
        // setData(res);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  // console.log(universities[0].intakes.splice(0, 4));

  const action = (page, range, items) => {
    setUniversities(items);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = itemsToSend.filter((val) => {
      // console.log("val", val);
      if (
        searchTerm === "" ||
        val.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      } else if (searchTerm.length >= 0) {
        return null;
      }
      return null;
    });
    setUniversities(filteredData);
  }, [searchTerm]);

  if (isLoading || !itemsToSend) return <Loading />;
  return (
    <>
      <Section gray>
        <Container>
          <Heading>Universities</Heading>
          <P style={{ textAlign: "center", margin: "0 auto" }}>
            Lorem ipsum is placeholder text commonly used in the graphic, print,
            and publishing industries for previewing layouts and visual mockups.
          </P>
        </Container>
      </Section>
      <Section>
        <Container>
          <SearchBar>
            <H1>All Universities</H1>
            <Search
              type="text"
              placeholder="Search for a university"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          <Wrapper>
            {universities?.length ? (
              universities.slice(0, 6).map((item, index) => {
                return (
                  <Card key={index}>
                    <Left>
                      <Image src={item.cover} />
                    </Left>
                    <Right>
                      <CardTitle>
                        <H1>{item.name}</H1>
                        <P>{item.city}</P>
                      </CardTitle>
                      <CardBody>
                        <CardText>
                          <P>Deposit:</P>
                          <H2>{item.deposit}</H2>
                        </CardText>
                        <CardText>
                          <P>Scholarship:</P>
                          <H2>{item.scholarship}</H2>
                        </CardText>
                        <CardText>
                          <P>Intakes:</P>
                          <IntakesWrapper>
                            {item.intakes?.map((intake, index) => {
                              // console.log(intake.slice(0, 3));
                              const isLast = index === item.intakes.length - 1;
                              return (
                                <H2 key={index}>
                                  {intake.slice(0, 3)}
                                  {!isLast && ",  "}
                                </H2>
                              );
                            })}
                          </IntakesWrapper>
                          {/* <H2>{item.intakes.slice(0, 4)}</H2> */}
                          {/* <H2>{item.intakes.slice(0, 3)}</H2> */}
                        </CardText>
                      </CardBody>
                      <CardButton>
                        <NavLink to={item.slug}>
                          <Button>View University</Button>
                        </NavLink>
                      </CardButton>
                    </Right>
                  </Card>
                );
              })
            ) : (
              <P>No results found</P>
            )}

            {/* {filteredData.map((item, index) => {
              return (
                
              );
            })} */}
          </Wrapper>
          <Pagination
            nextButton={true}
            prevButton={true}
            nextButtonLabel={"➡️"}
            prevButtonLabel={"⬅️"}
            items={itemsToSend}
            action={action}
            postsPerPage={postsPerPage}
          />
        </Container>
      </Section>
      <ScrollButton />
    </>
  );
}
