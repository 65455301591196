import React from "react";
import Container from "../styles/Container.styled";
import { H1 } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

const Sec = styled(Section)`
  padding: 40px 0;
`;

const Wrapper = styled.div`
  max-width: 870px;
  margin: 0 auto;
  text-align: center;
`;

export default function ContentSection({ title, content }) {
  return (
    <Sec>
      <Container>
        <Wrapper>
          <H1 center>{title}</H1>
          {/* <P center> </P> */}
          <PortableText blocks={content} />
        </Wrapper>
      </Container>
    </Sec>
  );
}
