import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../../components/styles/Container.styled";
import { device } from "../../../components/styles/devices";
import { H1, P } from "../../../components/styles/Fonts.styled";
import { Section } from "../../../components/styles/Section.styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  flex: 50% 0 0;
  padding: 0 30px;
  @media ${device.mobile} {
    flex: 100%;
    padding: 0 20px;
  }
`;
const ImageSection = styled.div`
  flex: 50% 0 0;
  img {
    padding: 0 100px;
  }

  @media ${device.mobile} {
    flex: 100%;
    img {
      padding: 0 40px;
    }
  }
`;

const Button = styled(Link)`
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #4f46e5;
  padding: 10px 20px;
  color: #e5e5e5;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
`;

export default function consultation() {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Content>
            <H1>Consultation</H1>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. ipsum
              dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. ipsum dolor sit amet,
              consectetur adipiscing elit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. ipsum dolor sit amet, consectetur
              adipiscing elit.
            </P>
            <div>
              <Button to="/consultation">Book your free slot</Button>
            </div>
          </Content>
          <ImageSection>
            <img src="./consultation.png" alt="img" />
          </ImageSection>
        </Wrapper>
      </Container>
    </Section>
  );
}
