import React from "react";
// import { Section } from "../styles/Section.styled";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { Link } from "react-router-dom";
import { device } from "../styles/devices";

const IHSLink =
  "https://www.immigration-health-surcharge.service.gov.uk/checker/type";

const Section = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 4rem 3rem;
  border-radius: 20px;
  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
    padding: 3rem 2rem;
  }
`;
const Content = styled.div`
  flex: 50% 0 0;

  @media ${device.mobile} {
    flex: 100%;
    padding-bottom: 20px;
  }
`;
const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  padding-left: 40px;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

const H1 = styled.h1`
  color: white;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
`;
const P = styled.p`
  color: white;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
`;
const Button = styled(Link)`
  color: black;
  width: calc(50% - 20px);
  padding: 10px 15px;
  background-color: white;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`;

const A = styled.a`
  color: black;
  width: calc(50% - 20px);
  padding: 10px 15px;
  background-color: white;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`;

export default function Tools() {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Content>
            <H1>Essential Tools</H1>
            <P>
              Variaty of tools to utilise while applying or after reaching out
              to UK. Might help you in so many things.
            </P>
          </Content>
          <ButtonSection>
            <Button to="/resume">
              Resume Maker <img src="./resume.svg" alt="resume" />
            </Button>
            <Button to="/templates">
              Templates Drafts
              <img src="./template.svg" alt="template" />
            </Button>
            <Button to="/calculator/financial-statement-calculator">
              Financial Calulator
              <img src="./finance.svg" alt="finance" />
            </Button>
            <A href={IHSLink}>
              IHS calculator
              <img src="./finance.svg" alt="finance" />
            </A>
          </ButtonSection>
        </Wrapper>
      </Container>
    </Section>
  );
}
