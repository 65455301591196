import React from "react";
import styled from "styled-components";
import { P } from "../../styles/Fonts.styled";
import "./bootstrap-grid.min.css";

import Experience from "./Experience";
import Skill from "./Skill";
import Contact from "./Contact";
import Education from "./Education";

const ResumeContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 595px;
  padding: 60px 0px;
  border: 2px solid #f2eeff;
  background: white;
`;

const InnerContainer = styled.div`
  width: 485px;
  margin-left: auto;
  margin-right: auto;
`;

const Name = styled.h2`
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.04em;
  color: #46464b;
  margin: 0;
  padding: 0;
`;

const Role = styled(P)`
  margin: 0;
  font-size: 14px;
  letter-spacing: -0.03em;
`;

const Summary = styled(P)`
  letter-spacing: -0.02em;
  font-size: 14px;
  margin: 20px 0 0 0;
  /* margin-top: 30px; */
  line-height: 1.38;
  font-weight: 400;
`;

const Wrapper = styled.div`
  margin-top: 45px;
`;

const Title = styled.h3`
  font-weight: bold;
  letter-spacing: -0.03em;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Skills = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const NoContent = styled.div``;

export default function CreateResume(props) {
  const resume = props.resume;
  // console.log(resume);
  return (
    <>
      <ResumeContainer>
        <InnerContainer>
          <div className="header row">
            <div className="col-7 basic-info">
              <Name>
                {resume.firstName || " firstName"}{" "}
                {resume.lastName || "lastName"}
              </Name>
              <Role>{resume.job || "Enter your job title"}</Role>
              <Summary>
                {resume.summary ||
                  "Enter a powerful summary to describe your overall experience and skills."}
              </Summary>
            </div>

            <div
              className="col-4 offset-1 contact-info"
              style={{ marginTop: "5px" }}
            >
              <Contact
                text={`${resume.city || "City"}, ${resume.state || "State"}`}
              />
              <Contact text={resume.email || "Your email"} />
              {resume.website ? <Contact text={resume.website} /> : null}
            </div>
          </div>

          <Wrapper>
            <div className="row">
              <div className="col-7 experiences">
                <Title>Experience</Title>
                {resume.experiences ? (
                  resume.experiences.map((experience, index) => {
                    return <Experience experience={experience} key={index} />;
                  })
                ) : (
                  <NoContent>You haven't added any experience yet</NoContent>
                )}
              </div>

              <div className="col-4 offset-1 skills">
                <Title>Skills</Title>
                <div>
                  <Skills>
                    {resume.skills ? (
                      resume.skills.map((skill, index) => (
                        <Skill skill={skill} key={index} />
                      ))
                    ) : (
                      <NoContent>You haven't added any skill yet</NoContent>
                    )}
                  </Skills>
                </div>
              </div>

              <div className="col-7 experiences">
                <Title>Education</Title>
                <div>
                  {resume.educations ? (
                    resume.educations.map((education, index) => {
                      return <Education education={education} key={index} />;
                    })
                  ) : (
                    <NoContent>You haven't added any skill yet</NoContent>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </InnerContainer>
      </ResumeContainer>
    </>
  );
}
