import React from "react";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import { Button, WhiteButton } from "../styles/Button.styled";
import { device } from "../styles/devices";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  width: 33%;
  padding: 40px 55px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 6px 10px #00000029;

  @media ${device.mobile} {
    width: 100%;
    margin: 10px 0;
    padding: 40px 30px;
  }
`;

const BlueCardContainer = styled(CardContainer)`
  background-color: #665dd3;
  color: ${({ theme }) => theme.colors.lightText};
  padding: 60px 55px;
  @media ${device.mobile} {
    padding: 50px 30px;
  }
`;

const Heading = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.textSize.md};
`;
const Title = styled.span`
  font-size: ${({ theme }) => theme.textSize.xs};
`;

const Price = styled.h1`
  padding-top: 30px;
  color: ${({ theme }) => theme.colors.primary};
`;

const MainPrice = styled(Price)`
  color: ${({ theme }) => theme.colors.lightText};
`;

const CardHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  margin: 20px 0;
  line-height: 1.3;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.span`
  background-color: #4237c8;
  opacity: 0.5;
  padding: 8px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
const LightIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: 0.5;
`;

export default function PricingCard({visaFee, premium, consultatioForm}) {
  // console.log(visaFee, premium)
  return (
    <Wrapper>
      <CardContainer>
        <CardHead>
          <Heading>Consultation call</Heading>
          <Price>Free!</Price>
        </CardHead>
        <List>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            15 minute call over Zoom or Skype
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Talk about your plans
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Analyse your profile
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Find the right fit of universities
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Clear all your doubts
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Answer any burning questions you may have.
          </Item>
        </List>
        <Button as={"a"} href={consultatioForm} target="_blank">Book Now</Button>
      </CardContainer>

      <BlueCardContainer>
        <CardHead>
          <Heading>Guaranteed Admission and Support</Heading>
          <Title>(including Visa Application Service)</Title>
          <MainPrice>£​150</MainPrice>
        </CardHead>
        <List>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            15 minute call over Zoom or Skype
          </Item>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            Talk about your plans
          </Item>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            Analyse your profile
          </Item>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            Find the right fit of universities
          </Item>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            Clear all your doubts
          </Item>
          <Item>
            <LightIcon>
              <FaCheck color={"#5F55CD"} size={14} />
            </LightIcon>
            Answer any burning questions you may have.
          </Item>
        </List>
        <WhiteButton as={"a"} href={premium} target="_blank">Buy Now</WhiteButton>
      </BlueCardContainer>

      <CardContainer>
        <CardHead>
          <Heading>Visa Application Service</Heading>
          <Price>£​75</Price>
        </CardHead>
        <List>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            15 minute call over Zoom or Skype
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Talk about your plans
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Analyse your profile
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Find the right fit of universities
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Clear all your doubts
          </Item>
          <Item>
            <Icon>
              <FaCheck color={"white"} size={14} />
            </Icon>
            Answer any burning questions you may have.
          </Item>
        </List>
        <Button as={"a"} href={visaFee} target="_blank">Buy Now</Button>
      </CardContainer>
    </Wrapper>
  );
}
