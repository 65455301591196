import React, { useState, useEffect } from "react";
import Container from "../../components/styles/Container.styled";
import { H1, H2, P } from "../../components/styles/Fonts.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";

import sanityClient from "../../client";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
`;
const InnerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
`;

const Title = styled(H2)`
  margin-bottom: 20px;
`;
const Name = styled(P)`
  text-align: center;
`;

export default function CurrentVolunteer() {
  const [volunteer, setVolunteer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    sanityClient
      .fetch(
        `*[_type == 'volunteer' && active == true ]{
            name,
            city
        }`
      )
      .then((data) => setVolunteer(data))
      .catch(console.error);
    setIsLoading(false);
  }, []);

  // console.log(volunteer);

  if (isLoading || !volunteer) return <p>loading</p>;
  return (
    <Section gray>
      <Container>
        <H1 center>Current Volunteers</H1>

        <Wrapper>
          <InnerSection>
            <Title>Name</Title>
            {/* {data.map((name, index) => {
              return <Name>{name}</Name>;
            })} */}
            {volunteer.map((data, index) => (
              <Name key={index}>{data.name}</Name>
            ))}
          </InnerSection>
          <InnerSection>
            <Title>Location</Title>
            {volunteer.map((data, index) => (
              <Name key={index}>{data.city}</Name>
            ))}
          </InnerSection>
        </Wrapper>
      </Container>
    </Section>
  );
}
