import React from "react";
// import Facebook from "../../components/Facebook";

import Process from "../../components/consultation/Process";
import Pricing from "../../components/consultation/Pricing";
import About from "../../components/consultation/About";
// import CaseStudy from "../../components/consultation/CaseStudy";
// import ReviewSlider from "../../components/consultation/ReviewSlider";
import Header from "../../components/consultation/Header";
import ScrollButton from "../../components/ScrollButton";

export default function Consultation() {
  return (
    <div>
      <Header />
      <Process />
      <About />
      <Pricing />
      {/* <CaseStudy /> */}
      {/* <ReviewSlider /> */}
      {/* <Facebook /> */}
      <ScrollButton />
    </div>
  );
}
