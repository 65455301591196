import React from "react";
import styled from "styled-components";
import { H2, Span } from "../../styles/Fonts.styled";
import { getYear } from "../../utils/date";
import { BsTrash } from "react-icons/bs";

const InnerWrapper = styled.div``;
const ExperienceSection = styled.div``;
const Head = styled.div``;
const IconContainer = styled.div``;
const ActionIcon = styled.span``;

export default function Education(props) {
  const handleDelete = (index) => {
    const newArr = props.getState("educations", []);
    newArr.splice(index, 1);
    props.setState("educations", newArr);
  };

  const { school, degree, startDate, endDate } = props.education;
  return (
    <ExperienceSection>
      <InnerWrapper>
        <Head>
          <H2> {school} </H2>
          <Span>{degree}</Span>
          <Span>
            {getYear(startDate)} -{" "}
            {Date.parse(endDate) ? getYear(endDate) : endDate}
          </Span>
        </Head>
        <IconContainer>
          <ActionIcon onClick={() => handleDelete(props.id)}>
            <BsTrash />
          </ActionIcon>
        </IconContainer>
      </InnerWrapper>
    </ExperienceSection>
  );
}
