import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/styles/Button.styled";
import Container from "../../components/styles/Container.styled";
import { H1, H2 } from "../../components/styles/Fonts.styled";
import { Center, Section } from "../../components/styles/Section.styled";
// import Card from '../../components/templates/Card'

import sanityClient from "../../client";
import Loading from "../../components/loading/Loading";
import { PortableText } from "@portabletext/react";
import { device } from "../../components/styles/devices";

const Heading = styled.div`
  height: 20vh;
  display: flex;
  align-items: center;
  max-width: 550px;
  /* flex-direction: column; */
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 70px auto;

  @media ${device.mobile} {
    width: 100%;
    margin: 30px auto;
  }
`;

// const InnerWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   align-items: center;
// `;

const A = styled(Button)`
  margin-right: 10px;
`;

export default function TemplateDetails(props) {
  let { slug } = useParams();

  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "template" && slug.current == '${slug}']{
      title,
      body,
      "document": document.asset->url
    }`
      )
      .then((data) => setTemplate(data[0]))
      .catch(console.error);
    setIsLoading(false);
  }, [slug]);

  // console.log(template);

  if (isLoading || !template) return <Loading />;

  return (
    <>
      <Section gray>
        <Container>
          <Center>
            <Heading>
              <H1 center>{template.title}</H1>
            </Heading>
          </Center>
        </Container>
      </Section>

      <Section>
        <Container>
          <Wrapper>
            <H2>{template.title}</H2>
            <PortableText
              value={template.body}
              components={{
                block: {
                  // Customize block types with ease
                  h1: ({ children }) => <H2>{children}</H2>,
                  normal: ({ children }) => (
                    <p style={{ whiteSpace: "pre-line", paddingBottom: 10 }}>
                      {children}
                    </p>
                  ),
                },
              }}
            />
            {/* <PortableText blocks={template.body} /> */}
            <A as={"a"} href={"/templates"} download>
              <Link to="/templates">Back</Link>
            </A>

            <A as={"a"} href={template.document} download>
              Download
            </A>
          </Wrapper>
        </Container>
      </Section>
    </>
  );
}
