import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/styles/Button.styled";
import Container from "../../components/styles/Container.styled";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
`;
const Head = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  h1 {
    padding: 5px;
    font-size: ${({ theme }) => theme.textSize.xl};
    color: ${({ theme }) => theme.colors.primary};
  }
  p {
    padding: 5px;
  }
`;

export default function NotFound() {
  return (
    <div>
      <Container>
        <Wrapper>
          <Head>
            <h1>404</h1>
            <p>NOT FOUND</p>
            <Button>Go back Home</Button>
          </Head>

          <Player
            autoplay
            loop
            src="./not-found-1.json"
            style={{ width: "400px", height: "400px", colors: "blue" }}
          ></Player>
        </Wrapper>
      </Container>
    </div>
  );
}
