export const startingOut = [
  {
    image: "/images/card-img-1@2x.png",
    title: "Beginners Guide",
    excerpt:
      "Step 1. Prepare all your educational documents: If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/whoami.png",
    username: "Fahad Saleem",
    createdAt: "March 20, 2020",
    link: "/guide/beginners-guide",
  },
  {
    image: "/images/card-img-2@2x.png",
    title: "Credibility Interview",
    excerpt:
      "Let’s discuss EXPENSES and cover some basic costs and see what benefits/discounts you get as a student in the UK.*Please note these are average estimations and you may spend more or less depending on your situation and the situation and the",
    avatar: "/images/whoami.png",
    username: "Username",
    link: "/guide/ace-your-university-and-ukvi-credibility-interview",
    createdAt: "March 20, 2",
  },
  {
    image: "/images/Dependent-visa-Blog-Banner-1200-x-630-px-v2-1.png",
    title: "Dependent requirements",
    excerpt:
      "Dependent requirements  Let’s talk about DEPENDENT Visas today.  Who can be your dependent?  Your Husband/Wife  Your Children  Civil Partner (Unmarried / Same-sex partners) – LOTS of documents required, high refusal chances – avoid this route unless it’s absolutely necessary.",
    avatar: "/images/whoami.png",
    username: "Fahad Saleem",
    link: "/guide/dependent-requirements",
    createdAt: "March 20, 3",
  },
];

export const whileApplying = [
  {
    image: "/images/card-img-4@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 1",
  },
  {
    image: "/images/card-img-5@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 2",
  },
  {
    image: "/images/card-img-6@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 3",
  },
];

export const afterReaching = [
  {
    image: "/images/card-img-7@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 1",
  },
  {
    image: "/images/card-img-8@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 2",
  },
  {
    image: "/images/card-img-9@2x.png",
    title: "Beginners Guide",
    excerpt:
      "If applying for Foundation/Undergraduate courses you need to have SSC and HSC or O / A Levels transcripts and certificates. For postgraduate courses, you need the above documents + Bachelors/Masters degree transcripts and certificates as well.",
    avatar: "/images/avatar@2x.png",
    username: "Username",
    link: "/home",
    createdAt: "March 20, 3",
  },
];
