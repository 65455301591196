import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Login } from "../pages";

export default function ProtectedRoutes() {
  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    async function checkLogin() {
      const user = localStorage.getItem("user");
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
    checkLogin();
  }, []);

  // console.log("user is: ", isLoggedIn);

  return isLoggedIn ? <Outlet /> : <Login />;
}
