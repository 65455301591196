import React from "react";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { Section } from "../styles/Section.styled";
import { H1 } from "../styles/Fonts.styled";
import PricingCard from "./PricingCard";
import { device } from "../styles/devices";

const MainWrapper = styled.div`
  margin: 0 30px;
  @media ${device.mobile} {
    margin: 0;
  }
`;

const consultatioForm = "https://docs.google.com/forms/d/e/1FAIpQLSfEZEucnaxDYF9i9n8x3FN4kryF3IZwSvm2mnfCNgVOF969YA/viewform";
const premium = "https://buy.stripe.com/6oEdS16VC8K65Ne28a"
const visaFee = "https://buy.stripe.com/7sI8xHfs8gcyfnOaEF"

export default function Pricing() {
  return (
    <Section>
      <Container>
        <MainWrapper>
          <H1>
            TRANSPARENT <span>PRICING PLANS!</span>
          </H1>
          <PricingCard premium={premium} visaFee={visaFee} consultatioForm={consultatioForm} />
        </MainWrapper>
      </Container>
    </Section>
  );
}
