import React from "react";
import Container from "../../components/styles/Container.styled";
import { H1 } from "../../components/styles/Fonts.styled";
import { Section } from "../../components/styles/Section.styled";

export default function PrivacyPolicy() {
  return (
    <Section>
      <Container>
        <H1 center>Privacy Policy</H1>
      </Container>
    </Section>
  );
}
