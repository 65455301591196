import React from "react";
import { Button } from "../styles/Button.styled";
import Container from "../styles/Container.styled";
import { H1, P } from "../styles/Fonts.styled";
import { ButtonWrapper, Wrapper } from "../styles/Form.styled";

import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import styled from "styled-components";
import { device } from "../styles/devices";

// import './tags.css';

const TagContainer = styled.div`
  margin: 20px 60px;

  @media ${device.mobile} {
    margin: 20px 0;
  }
`;

export default function StepFive(props) {
  const onBTNClickNext = (e) => {
    e.preventDefault();
    props.next();
  };
  const onBTNClickNextPrev = (e) => {
    e.preventDefault();
    props.prev();
  };

  // console.log(props)

  return (
    <div>
      <Container>
        <Wrapper>
          <H1 center>{props.title}</H1>

          <TagContainer>
            <P center>Just type and press enter to add</P>
            <ReactTagInput
              tags={props.getState("skills", [])}
              onChange={(newTags) => props.setState("skills", newTags)}
            />
          </TagContainer>
          <ButtonWrapper>
            <Button onClick={onBTNClickNextPrev}>Prev</Button>
            <Button onClick={onBTNClickNext}>Next</Button>
          </ButtonWrapper>
        </Wrapper>
      </Container>
    </div>
  );
}
