import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";

const Section = styled.div`
  flex: 30% 0 0;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  align-items: flex-end;
  flex-wrap: wrap;
  &:last-child {
    padding-bottom: 0;
  }
`;

const H1 = styled.h1`
  font-size: 20px;
`;
const P = styled.p`
  font-size: 18px;
  padding-left: 10px;
`;
// const A = styled(Link)``;

export default function SideBar({ university }) {
  return (
    <Section>
      <Wrapper>
        <Card>
          <H1>Desposit: </H1>
          <P>{university.deposit}₤</P>
        </Card>
        <Card>
          <H1>Scholarship: </H1>
          <P>{university.scholarship}₤</P>
        </Card>
        <Card>
          <H1>Accomodation: </H1>
          <P>{university.accomodation}₤</P>
        </Card>

        <Card>
          <H1>English Requirements: </H1>
          {university.englishRequirements?.map((requirement, index) => {
            const isLast = index === university.englishRequirements.length - 1;
            return (
              <P key={index}>
                {requirement}
                {!isLast && ",  "}
              </P>
            );
          })}
        </Card>
        <Card>
          <H1>Intakes: </H1>
          {university.intakes?.map((intake, index) => {
            const isLast = index === university.intakes.length - 1;
            return (
              <P key={index}>
                {intake.slice(0, 3)}
                {!isLast && ",  "}
              </P>
            );
          })}
        </Card>
        <Card>
          <H1>Campuses: </H1>
          {university.campuses?.map((campus, index) => {
            const isLast = index === university.campuses.length - 1;
            return (
              <P key={index}>
                {campus}
                {!isLast && ",  "}
              </P>
            );
          })}
        </Card>
      </Wrapper>
    </Section>
  );
}
