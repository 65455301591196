import React from "react";
import { Button } from "../styles/Button.styled";
import Container from "../styles/Container.styled";
import { H1, H2, P } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";
import styled from "styled-components";
import { device } from "../styles/devices";

const Head = styled.div`
    padding: 0 0 30px 20px;

    @media ${device.mobile} {
        padding: 0 0 20px 0 ;
    
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media ${device.mobile} {
    flex-direction: column;

  }
`;
const Card = styled.div`
  flex: 33%;
  padding: 20px;
  margin: 10px;
  justify-content: space-between;

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    
  }
`;
const Content = styled.div``;
const Image = styled.img`
  max-width: 100%;
  padding-bottom: 30px;
  max-height: 200px;
  object-fit: contain;
`;

export default function WhyJoinUs() {
  return (
    <Section>
      <Container>
        <Head>
          <H1>
            Why join our<span> Awesome Community?</span>
          </H1>
        </Head>
        <Wrapper>
          <Card>
            <Image src="/vector/locally.png" />
            <Content>
              <H2>Connect Locally</H2>
              <P>
                Find students near you by registering your profile. Several
                other features to be added for registered users in coming weeks
                to help find and post Jobs, Accommodation sharing, and more...
              </P>
              <Button>Join Us</Button>
            </Content>
          </Card>
          <Card>
            <Image src="/vector/connect.png" />
            <Content>
              <H2>Network</H2>
              <P>
                Connect and meet a plethora of students, mentors and Alumni from
                all across the UK with backgrounds just like YOURS!
              </P>
              <Button>Join Us</Button>
            </Content>
          </Card>
          <Card>
            <Image src="/vector/learn.png" />
            <Content>
              <H2>Learn</H2>
              <P>
                Know everthing you need to know about studying, living &
                progressing in the UK! Videos with students who made us proud!
                Community curated content.
              </P>
              <Button>Join Us</Button>
            </Content>
          </Card>
        </Wrapper>
      </Container>
    </Section>
  );
}
