import React from "react";
import Container from "../../components/styles/Container.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { H2 } from "../../components/styles/Fonts.styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const Card = styled.div`
  flex: 50%;
  margin: 10px;
  background-color: white;
  padding: 40px 25px;
  box-shadow: 0px 6px 10px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 260px;
  max-width: 100%;
  padding: 20px 0;
`;

const Button = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.textSize.sm};
  font-weight: 300;
  cursor: pointer;
`;

export default function Calculator() {
  return (
    <Section gray>
      <Container>
        <Wrapper>
          <Card>
            <H2 center>Financial Statement Calculator</H2>
            <Image src="/vector/bank-statement.png" />
            <Button to="/calculator/financial-statement-calculator">
              View More
            </Button>
          </Card>
          <Card>
            <H2 center>IHS Fee Calculator</H2>
            <Image src="/vector/ihs-calculator.png" />
            <Button to="/calculator/ihs-calculator">View More</Button>
          </Card>
        </Wrapper>
      </Container>
    </Section>
  );
}
