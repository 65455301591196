import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../styles/devices";

const CardContent = styled.div`
  width: calc(33% - 20px);
  margin: 0 10px 30px;
  height: auto;
  &:nth-child(3n) {
    margin-right: 0;
  }

  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CardContentHalf = styled(CardContent)`
  width: calc(50% - 20px);
  margin: 0 20px 30px 0;

  &:nth-child(2n) {
    margin-right: 0;
  }
`;
const ImageWrapper = styled.div`
  padding-bottom: 20px;
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
`;
const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 0 0 10px 5px;
`;
const Title = styled.h2`
  padding: 0;
  margin: 0;
  line-height: 1.4;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
const Paragraph = styled.p`
  margin: 20px 0;
  font-weight: 300;
  font-size: 16px;
`;
const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const UserImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  object-fit: cover;
`;
const UserName = styled.div`
  display: flex;
  flex-direction: column;
`;
const UserNameText = styled.h2`
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;
const Date = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

export function Card({ data }) {
  const { link, image, title, excerpt, username, avatar, createdAt } = data;

  return (
    <CardContent>
      {image && (
        <Link to={link}>
          <ImageWrapper>
            <Image src={image} />
          </ImageWrapper>
        </Link>
      )}

      <TextSection>
        <Link to={link}>
          <Title>{title}</Title>
        </Link>
        <Paragraph>{excerpt}</Paragraph>
      </TextSection>

      {username && (
        <User>
          {avatar && <UserImage src={avatar} />}
          <UserName>
            <UserNameText>{username}</UserNameText>
            <Date>{moment(createdAt).format("MMMM D, YYYY")}</Date>
          </UserName>
        </User>
      )}
    </CardContent>
  );
}

export function HalfCard({ data }) {
  const { link, image, title, excerpt, username, avatar, createdAt } = data;

  return (
    <CardContentHalf>
      <Link to={link}>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
      </Link>

      <TextSection>
        <Link to={link}>
          <Title>{title}</Title>
        </Link>
        <Paragraph>{excerpt}</Paragraph>
      </TextSection>

      <User>
        <UserImage src={avatar} />
        <UserName>
          <UserNameText>{username}</UserNameText>
          <Date>{moment(createdAt).format("MMMM D, YYYY")}</Date>
        </UserName>
      </User>
    </CardContentHalf>
  );
}

const ImageCardContent = styled.div`
  width: calc(33% - 20px);
  margin: 0 10px 30px;
  height: auto;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media ${device.mobile} {
    width: 100%;
    margin: 20px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${device.tablet} {
    width: calc(50% - 20px);
    margin: 0 20px 30px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
const Name = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
const Button = styled(Link)`
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 300;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: black;
    text-decoration: underline;
  }
`;

const ImageCardWrapper = styled(ImageWrapper)`
  padding-bottom: 0;
`;

const CardImage = styled(Image)`
  max-height: 180px;
`;

export const ImageCard = ({ data }) => {
  const { link, image, title } = data;

  // console.log("the link", link, title);

  return (
    <ImageCardContent>
      <Link to={link}>
        <ImageCardWrapper>
          {image ? (
            <CardImage src={image} />
          ) : (
            <CardImage src="./images/placeholder-image.png" />
          )}
        </ImageCardWrapper>
      </Link>

      <ButtonSection>
        <Name>{title}</Name>
        <Button to={link}>Explore</Button>
      </ButtonSection>
    </ImageCardContent>
  );
};
