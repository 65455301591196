import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BsArrowRight } from "react-icons/bs";
import { device } from "../../../components/styles/devices";

const backgroundVideo = "./background.mp4";
const Section = styled.div`
  width: 100%;
  min-height: 90vh;
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
`;

const Video = styled.video`
  width: 100%;
  height: 90vh;
  position: absolute;
  object-fit: cover;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  /* top: 0; */
  right: 0;
  left: 0;
  /* z-index: 2; */
  width: 100%;
  height: 90vh;
`;

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
  padding: 0 100px;
  justify-content: center;
  height: 90vh;
  max-width: 930px;

  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

const Heading = styled.h1`
  font-size: 5em;
  line-height: 1em;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 700;

  @media ${device.mobile} {
    font-size: 3em;
  }
`;

const Para = styled.p`
  text-align: left;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  margin: 0 0 20px;
  color: white;
  font-size: 1em;
`;

const Button = styled(Link)`
  background-color: #5046e5;
  color: white;
  font-size: 1em;
  font-weight: 300;
  padding: 10px 15px;
  border-radius: 3px;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
`;

export default function Header() {
  return (
    <Section>
      <Video autoPlay muted loop>
        <source src={backgroundVideo} />
      </Video>
      <Overlay></Overlay>
      <TextContainer>
        <Heading>Uk Study Guide For New Students</Heading>
        <Para>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation.{" "}
        </Para>
        <div>
          <Button to="/guide">
            Guide to UK <BsArrowRight size={16} style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </TextContainer>
    </Section>
  );
}
