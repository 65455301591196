import React from "react";
import Container from "../styles/Container.styled";
import { Button } from "../styles/Button.styled";
import { H1 } from "../styles/Fonts.styled";
import { ButtonWrapper, Wrapper } from "../styles/Form.styled";
import styled from "styled-components";
import { PDFExport } from "@progress/kendo-react-pdf";
import CreateResume from "./createResume";

const ResumeWrapper = styled.div`
  overflow: scroll;
`;

export default function Final(props) {
  // const onBTNClickNext = (e) => {
  //     e.preventDefault();
  //     props.next();
  // };
  const onBTNClickNextPrev = (e) => {
    e.preventDefault();
    props.prev();
  };

  let resume = null;
  const downloadDocument = () => {
    resume.save();
  };

  return (
    <div>
      <Container>
        <Wrapper>
          <H1 center>{props.title}</H1>
          <ResumeWrapper>
            <PDFExport
              fileName={`resume.pdf`}
              title=""
              subject=""
              keywords=""
              ref={(r) => (resume = r)}
            >
              {/* {console.log(props.state)} */}
              <CreateResume resume={props.state} />
            </PDFExport>
          </ResumeWrapper>
          <ButtonWrapper style={{ justifyContent: "space-between" }}>
            <Button onClick={onBTNClickNextPrev}>Prev</Button>
            <Button onClick={downloadDocument}>Download</Button>
            {/* <Button onClick={onBTNClickNext}>Next</Button> */}
          </ButtonWrapper>
        </Wrapper>
      </Container>
    </div>
  );
}
