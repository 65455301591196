import React, { useState } from "react";
import Container from "../../../components/styles/Container.styled";
import styled from "styled-components";
import { H2, Span } from "../../../components/styles/Fonts.styled";
import Avatar from "react-avatar";
import { Button } from "../../../components/styles/Button.styled";
import { ImageUploadModal } from "./MainContentModal";

const Section = styled.div`
  padding: 30px 0;
`;

const BackgroundHeader = styled.div`
  box-shadow: 0px 6px 10px #00000029;
  border-radius: 10px;
  background-image: url("/images/18410.jpg");
  min-height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  background-size: cover;
`;

const Box = styled.div`
  width: 100%;
  padding: 30px 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -170px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* width: 150px; */
  /* height: 170px; */
  object-fit: cover;
  /* border-radius: 10px; */
  border: 3px solid ${({ theme }) => theme.colors.primary};
  background-color: white;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  padding: 0 20px;
`;
const Name = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeading = styled(H2)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
`;

export default function Header({ user, isSameUser }) {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <Section>
      <Container>
        <BackgroundHeader />
        <Box>
          {user.image ? (
            <Image src={user.image} />
          ) : (
            <Avatar name={user.name} />
          )}
          <Details>
            <Name>
              <SubHeading style={{ marginBottom: 0 }}>{user.name}</SubHeading>
              <Span>{user.city}</Span>
              {/* {isSameUser ? <p>Edit Image </p> : null} */}
            </Name>
            <div>
              {isSameUser ? (
                <>
                  <ImageUploadModal
                    open={open}
                    onClose={closeModal}
                    user={user}
                  />
                  <Button onClick={openModal}>Edit Profile</Button>
                </>
              ) : (
                <Button>Get in Touch!</Button>
              )}
            </div>
          </Details>
        </Box>
      </Container>
    </Section>
  );
}
