import styled from "styled-components";
import { device } from "./devices";

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.textSize.xl};
  text-align: ${(props) => (props.center ? "center" : "left")};
  margin-bottom: 20px;
  font-weight: 700;
  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media ${device.mobile} {
    font-size: ${({ theme }) => theme.textSize.md};
    margin-bottom: 10px;
  }
`;

export const P = styled.p`
  font-size: ${({ theme }) => theme.textSize.sm};
  color: ${({ theme }) => theme.colors.darkText};
  text-align: ${(props) => (props.center ? "center" : "left")};
  width: 100%;
  max-width: 800px;
  /* margin-bottom: 20px; */
  line-height: 1.6;
  margin: ${(props) => (props.center ? "0 auto" : "0 0 20px")};
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.textSize.md};
  margin: 0;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.darkText};
  font-size: ${({ theme }) => theme.textSize.xs};
`;
