import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "./styles/Container.styled";
import { device } from "./styles/devices";
import { P } from "./styles/Fonts.styled";

const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const Copyright = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding: 15px 0;
  p {
    color: ${({ theme }) => theme.colors.darkText};
    text-align: center;
    font-size: 12px;
  }
`;
const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0 30px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 20px;
  p {
    font-size: ${({ theme }) => theme.textSize.sm};

    color: ${({ theme }) => theme.colors.lightGrey};
    margin-bottom: 20px;
  }
  @media ${device.mobile} {
    padding: 0;
    /* margin-bottom: 25px; */
    margin: 0 0 20px;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.darkText};
  padding: 3px 0;

  font-size: ${({ theme }) => theme.textSize.sm};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
const Details = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${device.mobile} {
    padding: 0;
  }
`;
const Image = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

export default function Footer() {
  return (
    <Section>
      <Container>
        <InnerWrapper>
          <Details>
            {/* <Image src="/images/footer-logo.png" alt="logo" /> */}
            <Image src="/logo.png" alt="logo" />
            <P>
              If applying for Foundation/Undergraduate courses you need to have
              SSC and HSC or O / A Levels transcripts and certificates.
            </P>
          </Details>
          <Links>
            <p>Guide</p>
            <StyledLink to={"/guide"}>Starting Out</StyledLink>
            <StyledLink to={"/guide"}>While Applying</StyledLink>
            <StyledLink to={"/guide"}>After Reaching</StyledLink>
            <StyledLink to={"/login"}>Signup/Login</StyledLink>
            
          </Links>
          <Links>
            <p>IMPORTANT LINKS</p>
            <StyledLink to={"/calculator"}>Fee Calculator</StyledLink>
            <StyledLink to={"/templates"}>Templates</StyledLink>
            <StyledLink to={"/volunteer"}>Volunteer</StyledLink>
            <StyledLink to={"/need-help"}>Need help?</StyledLink>
            <StyledLink to={"/guide"}>Guide</StyledLink>
          </Links>
          <Links>
            <p>Consultation</p>
            <StyledLink to={"/consultation"}>Book appointment</StyledLink>
            <StyledLink to={"/universities"}>Finding out Univerities</StyledLink>
            <StyledLink to={"/discover"}>Discover Cities</StyledLink>
            <StyledLink to={"/faqs"}>Need help?</StyledLink>
            <StyledLink to={"/consultation"}>How to Contact</StyledLink>
          </Links>
        </InnerWrapper>
        <Copyright>
          <p>Copyright © Pakistani Students in UK Guide - 2022</p>
        </Copyright>
      </Container>
    </Section>
  );
}
