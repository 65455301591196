import React from "react";
import Container from "../styles/Container.styled";
import { H1 } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

const Card = styled.div`
  width: 80%;
  background-color: #4338ca;
  padding: 50px 40px;
  position: relative;
  border-radius: 35px;
  margin: 0 auto;
  overflow: hidden;
`;

const Fixed = styled(H1)`
  position: absolute;
  bottom: -18px;
  right: 0;
  font-size: 130px;
  opacity: 0.2;
  margin: 0;
  line-height: 1;
`;

const Content = styled.div`
  z-index: 1;
  color: white;
`;

// const Heading = styled.h1`
//   font-size: 72px;
//   color: white;
// `;

// const Paragraph = styled.blockquote`
//   font-size: 36px;
//   color: white;
// `;

export default function TravelCard({ content }) {
  // console.log(content);
  return (
    <Section>
      <Container>
        <Card>
          <Content>
            <PortableText blocks={content} />
          </Content>
          <Fixed>TRAVEL</Fixed>
        </Card>
      </Container>
    </Section>
  );
}
