import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "react-avatar";

const Section = styled.div`
  flex: 70% 0 1;
`;

const Wrapper = styled.div``;

const Title = styled.h1``;
const Description = styled.p``;

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const Image = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default function MainContent({ university }) {
  const [isVisible, setIsVisible] = useState({
    isVisible: false,
    id: null,
  });

  console.log(isVisible)

  return (
    <Section>
      <Wrapper>
        <Title>Members in this Univeristy</Title>
        <InnerWrapper>
          {university.users?.map((user, index) => {
            return (
              <>
                <ImageContainer key={index}>
                  {user.userImage ? (
                    <Image
                      src={user.userImage}
                      onMouseEnter={() =>
                        setIsVisible({ isVisible: true, id: user._id })
                      }
                    />
                  ) : (
                    <Avatar
                      name={user.name}
                      onMouseEnter={() =>
                        setIsVisible({ isVisible: true, id: user._id })
                      }
                      round={true}
                      size={50}
                    />
                  )}
                  <Name>{user.name}</Name>
                </ImageContainer>
              </>
            );
          })}
        </InnerWrapper>
        <Description></Description>
        <Title></Title>
        <Title>Location</Title>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8653.518577475397!2d-2.1015257!3d57.16476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa5ca7f4f50108e!2sUniversity%20of%20Aberdeen!5e0!3m2!1sen!2s!4v1653830710474!5m2!1sen!2s"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          loading="lazy"
          title="map"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Wrapper>
    </Section>
  );
}
