import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../styles/Container.styled";
import { device } from "../styles/devices";
import { Section } from "../styles/Section.styled";

const youtube_URL = "https://www.youtube.com/channel/UCq_PirKOhx1tXNYU_Ko-eLw";

const Wrapper = styled.div`
  background-color: #c4302b;
  padding: 4rem 3rem;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.mobile} {
    flex-direction: column;
    padding: 3rem 2rem;
  }
`;
const Content = styled.div`
  width: 50%;
  padding-right: 20px;

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
  }
`;

const YoutubeLogo = styled.img`
  width: 140px;
  padding-top: 20px;
`;
const H1 = styled.h1`
  color: white;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  padding-bottom: 10px;
`;
const P = styled.p`
  color: white;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
`;
const YoutubeContainer = styled.div`
  /* flex: 50% 0 0; */
  width: 50%;
  border-radius: 20px;
  overflow: hidden;
  /* min-height: 400px; */

  @media ${device.mobile} {
    width: 100%;
  }
`;

const Image = styled.img`
  background-color: #c4302b;
  /* border-radius: 50%; */
  padding: 15px 30px;
  border-radius: 10px;
  color: white;
`;

export default function Youtube() {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Content>
            <H1>Our Channel</H1>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </P>
            <Link to={youtube_URL}>
              <YoutubeLogo src="./youtubeLogo.png" />
            </Link>
          </Content>
          <YoutubeContainer>
            <ReactPlayer
              url={"https://www.youtube.com/embed/lBMRY0kb6Y4"}
              width="100%"
              // height="100%"
              playing
              playIcon={<Image src="./play-white.svg" />}
              light={"https://dev.ukstudy.guide/images/placeholder-image.png"}
              // style={{ borderRadius: "20px" }}
            />
          </YoutubeContainer>
        </Wrapper>
      </Container>
    </Section>
  );
}
