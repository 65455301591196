import React from "react";
import styled from "styled-components";

import Container from "../styles/Container.styled";

import { Button } from "../../components/styles/Button.styled";
import { Player } from "@lottiefiles/react-lottie-player";
import { device } from "../styles/devices";
import { H1, P } from "../styles/Fonts.styled";
import { Section } from "../styles/Section.styled";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 20px 70px;
  height: 90vh;

  @media ${device.mobile} {
    flex-direction: column-reverse;
    height: 100vh;
    padding: 20px;
  }
`;

const Head = styled.div`
  max-width: 500px;
  flex: 1;
`;

const ImageSection = styled.div`
  width: 500px;

  @media ${device.mobile} {
    margin: 20px;
    width: 300px;
  }
`;

export default function Header() {
  return (
    <Section variant="head">
      <Container>
        <Wrapper>
          <Head>
            <H1>
              YOUR STUDY ABROAD <span>JOURNEY STARTS HERE.</span>
            </H1>
            <P>
              This will give you a rough estimate of what you need. You’re
              required to double check everything before making your decision.
            </P>
            <Button>Lets Travel!</Button>
          </Head>
          <ImageSection>
            <Player
              autoplay
              //  loop
              speed={0.3}
              keepLastFrame={true}
              src="./passport.json"
              // style={{ width: '500px' }}
            ></Player>
          </ImageSection>
        </Wrapper>
      </Container>
    </Section>
  );
}
