import React from "react";
import Facebook from "../../components/Facebook";
import Tools from "../../components/home/Tools";
import Slider from "../../components/Slider";
import { startingOut } from "../../components/content/Home";
import Youtube from "../../components/home/Youtube";
import { Section } from "../../components/styles/Section.styled";
import { Wrapper } from "../../components/NewCard/Wrapper";
import { Card } from "../../components/NewCard/Card";
import Container from "../../components/styles/Container.styled";
import WhyJoinUs from "../../components/home/WhyJoinUs";
import styled from "styled-components";
import { H1 } from "../../components/styles/Fonts.styled";
import { Link } from "react-router-dom";
import { device } from "../../components/styles/devices";
import Consultation from "./Components/Consultation";

import Header from "./Components/Header";
import BottomBar from "./Components/BottomBar";
import ScrollButton from "../../components/ScrollButton";

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0 20px 20px;

  @media ${device.mobile} {
    margin: 0 0 20px 0;
    flex-direction: row;
    align-items: center;
  }
`;

const A = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.textSize.xs};
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const NewCard = ({ blog }) => {
  return (
    <Section>
      <Container>
        <Head>
          <H1 style={{ margin: 0, paddingRight: 10 }}>
            Guide to <span>United Kingdom</span>
          </H1>
          <A to="/guide">View All</A>
        </Head>
        <Wrapper>
          {blog.map((item, index) => (
            <Card data={item} key={index} />
          ))}
        </Wrapper>
      </Container>
    </Section>
  );
};

export default function Home() {
  return (
    <div>
      <Header />
      <NewCard blog={startingOut} />
      <Slider />
      <WhyJoinUs />
      <Tools />
      <Consultation />
      <Facebook />
      <Youtube />
      <BottomBar />
      <ScrollButton style={{position: "fixed", bottom: "17%"}}/>
    </div>
  );
}
