import React from "react";
import { Step, Steps } from "react-step-builder";
import StepOne from "../../components/resume/StepOne";
import StepTwo from "../../components/resume/StepTwo";
import StepThree from "../../components/resume/StepThree";
import StepFour from "../../components/resume/StepFour";
import StepFive from "../../components/resume/StepFive";
import Final from "../../components/resume/Final";

export default function Resume() {
  return (
    <>
      <Steps>
        <Step title="Initial Information" component={StepOne} />
        <Step title="Where you from?" component={StepTwo} />
        <Step title="Add Experiences" component={StepThree} />
        <Step title="Your past Education?" component={StepFour} />
        <Step title="Skills!" component={StepFive} />
        <Step title="Preview Your Resume" component={Final} />
      </Steps>
    </>
  );
}
