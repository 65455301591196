import React, { useState } from "react";
import Container from "../../styles/Container.styled";
import { Button } from "../../styles/Button.styled";
import { H1 } from "../../styles/Fonts.styled";
import { ButtonWrapper, Wrapper } from "../../styles/Form.styled";
import "react-responsive-modal/styles.css";
import ExperienceModal from "./ExperienceModal";
import Experience from "./Experience";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function StepThree(props) {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const onBTNClickNext = (e) => {
    e.preventDefault();
    props.next();
  };
  const onBTNClickNextPrev = (e) => {
    e.preventDefault();
    props.prev();
  };

  // console.log(props)

  return (
    <div>
      <Container>
        <Wrapper>
          <H1 center>{props.title}</H1>

          {props.getState("experiences", []).map((experience, index) => {
            return (
              <Experience
                key={index}
                id={index}
                experience={experience}
                getState={props.getState}
                setState={props.setState}
              />
              // <div key={index}>
              //   <p>{experience.title}</p>
              // </div>
            );
          })}

          <ExperienceModal
            open={open}
            onClose={closeModal}
            getState={props.getState}
            setState={props.setState}
          />
          <ButtonContainer>
            <Button onClick={openModal}>Add experience</Button>
          </ButtonContainer>
          {/* <ExperienceButton onClick={openModal}>Add experience</ExperienceButton> */}

          <ButtonWrapper>
            <Button onClick={onBTNClickNextPrev}>Prev</Button>
            <Button onClick={onBTNClickNext}>Next</Button>
          </ButtonWrapper>
        </Wrapper>
      </Container>
    </div>
  );
}
