import React, { useState, useEffect } from "react";
import Container from "../../components/styles/Container.styled";
import { Section } from "../../components/styles/Section.styled";
import styled from "styled-components";
import { H1 } from "../../components/styles/Fonts.styled";
import { Link } from "react-router-dom";

import sanityClient from "../../client";
import Loading from "../../components/loading/Loading";
import Avatar from "react-avatar";
import { Input } from "../../components/styles/Form.styled";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
`;

const SearchBox = styled.div``;

const Wrapper = styled.div``;

const User = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 30px 40px;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 6px 10px #00000029;

`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 40px;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Name = styled.h1`
  font-size: 22px;
  padding-bottom: 10px;
`;
const Details = styled.div`
  display: flex;
  flex-direction: row;
`;

const University = styled.p``;

const InnerDetails = styled.div`
  padding-right: 20px;
`;

const H2 = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
`;

const Span = styled.span`
  font-size: 14px;
`;

const Button = styled(Link)`
  padding: 10px 15px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
`;

// const UserPlaceholder = styled(Avatar)

export default function Users() {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await sanityClient
      .fetch(
        `*[_type == 'users']{
        "id": _id,
        name,
        "image": image.asset->url,
        "city": city->title,
        "university": university->name,
      }`
      )

      .then((res) => {
        setUserData(res);
        setIsLoading(false);
      })
      .catch(console.error());
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("user Data =====>>", University);

  if (isLoading || !userData) return <Loading />;

  return (
    <Section>
      <Container>
        <Header>
          <H1> All Users</H1>
          <SearchBox>
            <Input placeholder="Search members" />
            {/* <Button>Search</Button> */}
          </SearchBox>
        </Header>

        <Wrapper>
          {userData.map((user, index) => {
            return (
              <User key={index}>
                {user.image ? (
                  <Image src={user.image} />
                ) : (
                  <Avatar name={user.name} size={100} round={true} style={{marginRight: 40}} />
                )}

                <Content>
                  <Name>{user?.name}</Name>
                  <Details>
                    <InnerDetails>
                      <H2>University</H2>
                      <Span>{user?.university}</Span>
                    </InnerDetails>
                    <InnerDetails>
                      <H2>City</H2>
                      <Span>{user?.city}</Span>
                    </InnerDetails>
                  </Details>
                </Content>
                <div>
                  <Button to={user?.id}>View Profile</Button>
                </div>
              </User>
            );
          })}
        </Wrapper>
      </Container>
    </Section>
  );
}
