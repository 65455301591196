import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { Card } from "../../NewCard/Card";
// import ReactPaginate from "react-paginate";
import { Card } from "../../../components/NewCard/Card";
import { Section } from "../../../components/styles/Section.styled";
import Container from "../../../components/styles/Container.styled";
import { H1, P } from "../../../components/styles/Fonts.styled";
import sanityClient from "../../../client";
import Pagination from "react-sanity-pagination";
import Loading from "../../../components/loading/Loading";

const Wrapper = styled.div``;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Search = styled.input`
  width: 200px;
  padding: 10px 5px;
  margin: 10px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const itemsToSend = [];

export default function BlogCard() {
  const [guides, setGuides] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const postsPerPage = 9;

  const fetchBlogs = async () => {
    setIsLoading(true);
    await sanityClient
      .fetch(
        `*[_type == "blog" ]{
        "createdAt": _createdAt,
        title,
        excerpt,
        "link": slug.current,
        "image": cover.asset->url,
        "username": author->name,
        "avatar": author->image.asset->url,
        "category": category[0]->description,
      }`
      )
      .then((res) => {
        itemsToSend.push(...res);
        // setBlogs(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const action = (page, range, items) => {
    setGuides(items);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const filteredData = itemsToSend.filter((val) => {
      if (
        searchTerm === "" ||
        val.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      } else if (searchTerm.length >= 0) {
        return null;
      }
      return null;
    });
    setGuides(filteredData);
  }, [searchTerm]);

  if (isLoading || !itemsToSend) return <Loading />;

  return (
    <Section>
      <Container>
        <Wrapper>
          <Head>
            <H1>All blogs</H1>
            <Search
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Head>
          <InnerWrapper>
            {guides?.length ? (
              guides
                .slice(0, 9)
                .map((guide, index) => <Card data={guide} key={index} />)
            ) : (
              <P>No guides founds</P>
            )}
          </InnerWrapper>
          <Pagination
            nextButton={true}
            prevButton={true}
            nextButtonLabel={"➡️"}
            prevButtonLabel={"⬅️"}
            items={itemsToSend}
            action={action}
            postsPerPage={postsPerPage}
          />
        </Wrapper>
      </Container>
    </Section>
  );
}
