import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/styles/Button.styled";
import Container from "../../../components/styles/Container.styled";
import { H1 } from "../../../components/styles/Fonts.styled";
import { Section } from "../../../components/styles/Section.styled";

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const UniImage = styled.div`
  width: 30%;
`;
const UniTitle = styled.div`
  width: 70%;
  padding-left: 30px;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 20px;
`;

export default function UniHeader({university}) {
  return (
    <Section gray>
      <Container>
        <Head>
          <UniImage>
            <Image src={university.cover} />
          </UniImage>
          <UniTitle>
            <H1>{university.name}</H1>
            {/* <H2>{university.university.city}</H2> */}
            <Button>Apply Now</Button>
          </UniTitle>
        </Head>
      </Container>
    </Section>
  );
}
