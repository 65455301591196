import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import styled from "styled-components";
import Facebook from "../../components/Facebook";
import Container from "../../components/styles/Container.styled";
import { device } from "../../components/styles/devices";
import { H1, P } from "../../components/styles/Fonts.styled";
import { Center, Section } from "../../components/styles/Section.styled";
import Loading from "../../components/loading/Loading";
import { Wrapper } from "../../components/NewCard/Wrapper";
import { ImageCard } from "../../components/NewCard/Card";
import Pagination from "react-sanity-pagination";
import "../../components/pagination.css";
import ScrollButton from "../../components/ScrollButton";

const Content = styled(Center)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  @media ${device.mobile} {
    height: 40vh;
  }
`;

const Heading = styled(H1)`
  color: white;
  margin: 0 20px;
`;

const Head = styled(Section)`
  background-image: url("/images/bg-discover.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Search = styled.input`
  width: 200px;
  padding: 10px 5px;
  margin: 10px;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;
// const CardSection = ({ cities }) => {
//   return (
//     <Section>
//       <Container>
//         <Wrapper>
//           {cities.map((city, index) => (
//             <ImageCard data={city} key={index} />
//           ))}
//         </Wrapper>
//       </Container>
//     </Section>
//   );
// };

const itemsToSend = [];

export default function Discover() {
  const [cities, setCities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const postsPerPage = 9;

  const fetchCities = async () => {
    await sanityClient
      .fetch(
        `*[_type == 'discover' ]{
        "link": slug.current,
        title,
        "image": coverImage.asset->url,
      }  | order(_createdAt asc) `
      )
      .then((res) => {
        itemsToSend.push(...res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const action = (page, range, items) => {
    setCities(items);
  };

  useEffect(() => {
    fetchCities();
  }, []);

  // Search the data through pagination useEffect
  useEffect(() => {
    const filteredData = itemsToSend.filter((val) => {
      if (
        searchTerm === "" ||
        val.title.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return val;
      } else if (searchTerm.length >= 0) {
        return null;
      }
      return null;
    });
    setCities(filteredData);
  }, [searchTerm]);


  if (isLoading || !itemsToSend) return <Loading />;

  // const filteredData = cities.filter((val) => {
  //   if (
  //     searchTerm === "" ||
  //     val.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   ) {
  //     return val;
  //   }
  // });

  // if (filteredData.length === 0) {
  //   console.error("no data");
  // }

  return (
    <>
      <Head>
        <Container>
          <Content>
            <Heading center> Lets Discover Different Cities</Heading>
          </Content>
        </Container>
      </Head>
      {/* <Container style={{ marginTop: 20 }}></Container> */}

      {/* <Section> */}
      <Container style={{ paddingTop: 50 }}>
        <TopBar>
          <H1>All Cities</H1>
          <Search
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </TopBar>
        <Wrapper>
          {cities.length ? (
            cities
              .slice(0, 9)
              .map((city, index) => <ImageCard data={city} key={index} />)
          ) : (
            <P>No results found</P>
          )}
        </Wrapper>
      </Container>
      {/* </Section> */}

      {/* {cities && <CardSection cities={cities} />} */}
      <Container style={{paddingBottom: "20px"}}>
        <Pagination
          nextButton={true}
          prevButton={true}
          nextButtonLabel={"➡️"}
          prevButtonLabel={"⬅️"}
          items={itemsToSend}
          action={action}
          postsPerPage={postsPerPage}
        />
      </Container>
      <Facebook />
      <ScrollButton />
    </>
  );
}
