import React from "react";
import styled from "styled-components";
import Container from "../../../components/styles/Container.styled";
import { device } from "../../../components/styles/devices";
import { H1 } from "../../../components/styles/Fonts.styled";
import { Section } from "../../../components/styles/Section.styled";

const Head = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    height: 30vh;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Heading = styled(H1)`
  font-size: 82px;
  @media ${device.mobile} {
    font-size: 40px;
  }
`;

export default function Header() {
  return (
    <Section gray variant="head">
      <Container>
        <Head>
          <Heading>The Guide</Heading>
          {/* <Search>
            <Input placeholder="How to apply to unis..." />{" "}
            <StyledButton>Search</StyledButton>
          </Search> */}
        </Head>
      </Container>
    </Section>
  );
}
