import ReactTagInput from "@pathofdev/react-tag-input";
// import imageUrlBuilder  from "@sanity/image-url";
import React, {  useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../components/styles/Button.styled";
import { device } from "../../../components/styles/devices";
import { H1, H2, P } from "../../../components/styles/Fonts.styled";
import sanityClient from "../../../client";
import client from "../../../client";
// import { Editor } from "react-draft-wysiwyg";
// import {
//   // convertFromHTML,
//   // convertFromRaw,
//   // convertToRaw,
//   EditorState,
// } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import draftToHtml from "draftjs-to-html";

const TagContainer = styled.div`
  margin: 20px 60px;

  @media ${device.mobile} {
    margin: 20px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  padding-bottom: 20px;
  width: 100%;
`;

const Textfield = styled.textarea`
  width: 700px;
  max-width: 100%;
  resize: none;
  height: 200px;
  font-family: Poppins;
  font-size: 16px;
  padding: 10px;
`;

export default function MainContentModal() {
  return <div>MainContentModal</div>;
}

export function ImageUploadModal(props) {
  const [imagesAssets, setImagesAssets] = useState(null);
  const [wrongTypeofImage, setWrongTypeofImage] = useState(false);

  const [image, setImage] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  // const navigate = useNavigate()

  const handleChange = (e) => {
    const selectedImage = e.target.files[0];

    // const build = imageUrlBuilder(sanityClient);
    // const urlFor = build.image(selectedImage);

    if (
      selectedImage.type === "image/png" ||
      selectedImage.type === "image/svg" ||
      selectedImage.type === "image/jpeg" ||
      selectedImage.type === "image/gif" ||
      selectedImage.type === "image/tiff"
    ) {
      setWrongTypeofImage(false);
      setImage(selectedImage);
      setIsDisabled(false);

      sanityClient.assets
        .upload("image", selectedImage, {
          contentType: selectedImage.type,
          filename: selectedImage.name,
        })
        .then((document) => {
          setImagesAssets(document);
        })
        .catch((error) => {
          console.log("Upload failed:", error.message);
        });
    } else {
      setWrongTypeofImage(true);
    }
  };

  const saveImage = () => {
    if (imagesAssets?._id) {
      sanityClient.assets
        .upload("image", image, {
          filename: image.name.replaceAll(" ", "_"),
          contentType: image.type,
        })
        .then((imageAsset) => {
          return client
            .patch(props.user.id)
            .set({
              image: {
                _type: "image",
                asset: {
                  _type: "reference",
                  _ref: imageAsset._id,
                },
              },
            })
            .commit();
        })
        .then((res) => {
          console.log(res, "Done!");
        })
        .then(() => {
          toast.success("Image uploaded successfully!");
          // setTimeout(() => {
          //   props.onClose();
          //   navigate(`/profile/${props.user.id}`);
          //   // reload page
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log("Upload failed:", error.message);
          setIsDisabled(false);
        });
    } else {
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        open={props.open}
        onClose={props.onClose}
        closeOnOverlayClick={true}
        center
      >
        <Wrapper>
          <H1>Image Upload Modal</H1>

          {image && <Img src={URL.createObjectURL(image)} alt="image" />}
          <input
            type="file"
            name="profile"
            onChange={handleChange}
            accept="image/png, image/gif, image/jpeg"
            a
          />
          {wrongTypeofImage && (
            <p>wrong type of image please upload another one.</p>
          )}
          {!isDisabled && <Button onClick={saveImage}>Upload Image</Button>}
          {/* <Button onClick={saveImage}>Upload Image</Button> */}
        </Wrapper>
      </Modal>
    </>
  );
}

export function AboutModal(props) {
  // console.log("props", props.user);

  // const overview = props.user.bio;

  // const contentDataState = ContentState.createFromBlockArray(
  //   convertFromHTML(overview)
  // );
  // const editorDataState = EditorState.createWithContent(contentDataState);

  // const [editorState, setEditorState] = useState(editorDataState);

  //   const onEditorStateChange = (editorStateData) => {
  //       setEditorState(editorStateData);
  //   };

  //   const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   console.log(data);

  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );

  // useEffect(() => {
  //   // console.log(editorState);
  // }, [editorState]);

  const [bio, setBio] = useState(props.user.bio);

  const navigate = useNavigate()

  const handleChange = (e) => {
    console.log(e.target.value);
    setBio(e.target.value);
  };

  const postBio = () => {
    const mutate = {
      mutations: [
        {
          patch: {
            id: props.user.id,
            set: {
              bio: bio,
            },
          },
        },
      ],
    };

    const myPromise = fetch(
      `https://${process.env.REACT_APP_SANITY_PROJECT_ID}.api.sanity.io/${process.env.REACT_APP_SANITY_VERSION}/data/mutate/${process.env.REACT_APP_SANITY_DATASET_NAME}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_PROJECT_TOKEN}`,
        },
        body: JSON.stringify(mutate),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          props.onClose();
          navigate(`/profile/${props.user.id}`);
          // reload page
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));

    toast.promise(myPromise, {
      loading: "Sending data to server..",
      success: "Profile updated successfully",
      error: "Error when fetching",
    });
  };

  const submitHandle = (e) => {
    e.preventDefault();
    // setBio(e.target.value);

    postBio();
  };

  // console.log("im the bio", bio);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnOverlayClick={true}
      center
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <H1>About Modal</H1>
        </div>
        <form
          onSubmit={submitHandle}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Textfield
            defaultValue={bio || ""}
            placeholder="Tell us about yourself"
            onChange={handleChange}
          />
          <div style={{ marginTop: 10 }}>
            <Button>Update</Button>
          </div>
        </form>

        {/* <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorState={editorState}
          onEditorStateChange={setEditorState}
          // initialContentState={props.user.bio}
        /> */}
      </div>
    </Modal>
  );
}

export function SkillModal(props) {
  const [value, setValue] = useState(props.user?.skills || []);

  const navigate = useNavigate();

  const postData = (array) => {
    const data = {
      mutations: [
        {
          patch: {
            id: props.user.id,
            set: {
              skills: array,
            },
          },
        },
      ],
    };

    // console.log(data);

    const myPromise = fetch(
      `https://${process.env.REACT_APP_SANITY_PROJECT_ID}.api.sanity.io/${process.env.REACT_APP_SANITY_VERSION}/data/mutate/${process.env.REACT_APP_SANITY_DATASET_NAME}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_PROJECT_TOKEN}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        res.json();
      })
      .then((res) => {
        console.log(res);
        toast.success("Successfully updated skills");
        setTimeout(() => {
          props.onClose();
          navigate(`/profile/${props.user.id}`);
          // reload page
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating skills");
      });

    toast.promise(myPromise, {
      loading: "Sending data to server..",
      success: "Profile updated successfully",
      error: "Error when fetching",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(value);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnOverlayClick={true}
      center
    >
      <Toaster />

      <H2>Skills</H2>
      <form onSubmit={handleSubmit}>
        <TagContainer>
          <P center>Just type and press enter to add</P>
          <ReactTagInput
            tags={value}
            onChange={(newTags) => setValue(newTags)}
          />
        </TagContainer>
        <Button>Update</Button>
      </form>
      <P>*it will take few mins to update information on server</P>
    </Modal>
  );
}

export function InterestModal(props) {
  // console.log("im the user", props);
  const [value, setValue] = useState(props.user?.interests || []);

  const navigate = useNavigate();

  const postData = (array) => {
    const data = {
      mutations: [
        {
          patch: {
            id: props.user.id,
            set: {
              interests: array,
            },
          },
        },
      ],
    };

    // console.log(data);

    const myPromise = fetch(
      `https://${process.env.REACT_APP_SANITY_PROJECT_ID}.api.sanity.io/${process.env.REACT_APP_SANITY_VERSION}/data/mutate/${process.env.REACT_APP_SANITY_DATASET_NAME}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_SANITY_PROJECT_TOKEN}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        res.json();
      })
      .then((res) => {
        console.log(res);
        toast.success("Successfully updated interests");
        setTimeout(() => {
          props.onClose();
          navigate(`/profile/${props.user.id}`);
          // reload page
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error updating interests");
      });

    toast.promise(myPromise, {
      loading: "Sending data to server..",
      success: "Profile updated successfully",
      error: "Error when fetching",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const array = value;
    postData(array);
    navigate(`/profile/${props.user.id}`);
    // console.log(array);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnOverlayClick={true}
      center
    >
      <Toaster />

      <H2>Interest Modal</H2>
      <form onSubmit={handleSubmit}>
        <TagContainer>
          <P center>Just type and press enter to add</P>
          <ReactTagInput
            tags={value}
            onChange={(newTags) => setValue(newTags)}
          />
        </TagContainer>
        <Button>Update</Button>
      </form>
      <P>*it will take few mins to update information on server</P>
    </Modal>
  );
}
